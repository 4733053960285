import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import { Link } from "react-router-dom";
import axios from "axios";

Sale01.propTypes = {};

function Sale01(props) {
  const [Image, setImage] = useState("");
  const [advert_link, setadvert_link] = useState("");

  useEffect(() => {
    axios(process.env.REACT_APP_SERVER_URL + `/api/adverts?&populate=*`).then(
      (res) => {
        if (res.status == 200) {
          console.log("adveert", res.data.data[0].attributes.image);

          setImage(
            process.env.REACT_APP_SERVER_URL +
              res.data.data[0].attributes.image.data.attributes.formats.large
                .url
          );
          setadvert_link(res.data.data[0].attributes.url);
        }
      }
    );
  }, []);

  const [dataBlock] = useState({
    title: "Earn with Web3 Games",
    desc: "Discover the best Web3 Games to play and earn! Put them on your favourites list after registering! ",
  });
  return (
    <section className="section-sale" style={{ padding: "5px" }}>
      <a style={{ width: "100%" }} href={advert_link} target="_blank">
        <div
          className="container"
          style={{ display: "flex", justifyContent: "center" }}
        >
          <img className="advert_img" src={Image} alt="" />
        </div>
      </a>
    </section>
  );
}

export default Sale01;
