import React, { useState, useEffect, useContext } from "react";

import { Link, NavLink, useNavigate } from "react-router-dom";
import menus from "../../pages/menu";
import { Dropdown } from "react-bootstrap";

import "./styles.scss";
import logo from "../../assets/images/logo/logo.png";
import logodark from "../../assets/images/logo/logo-dark.png";
import avt from "../../assets/images/avt/avt-01.jpg";
import DarkMode from "./DarkMode";

import icon1 from "../../assets/images/flags/us.jpg";
import icon2 from "../../assets/images/flags/spain.jpg";
import icon3 from "../../assets/images/flags/germany.jpg";
import icon4 from "../../assets/images/flags/italy.jpg";
import icon5 from "../../assets/images/flags/russia.jpg";
import Countdown from "react-countdown";
import axios from "axios";
import CountdownDisplay from "./CountdownDisplay";
import { CoinsContext } from "../CoinsContext";
// import Button from '../button';

const StatsHeader = () => {
  const [scroll, setScroll] = useState(false);
  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 100);
    });

    window.onclick = (e) => {
      if (
        e.target != document.getElementById("search_form") &&
        e.target != document.getElementById("search_input") &&
        e.target != document.getElementById("search_result") &&
        e.target != document.getElementById("search_name") &&
        e.target != document.getElementById("search_ticker") &&
        e.target != document.getElementById("search_img")
      ) {
        setisfocus(false);
      }
    };

    return () => {
      setScroll({});
    };
  }, []);

  const [menuActive, setMenuActive] = useState(null);

  const handleMenuActive = () => {
    setMenuActive(!menuActive);
  };

  const [activeIndex, setActiveIndex] = useState(null);

  const [CoinsNo, setCoinsNo] = useState("");
  const [AllCoins, setAllCoins] = useState([]);
  const [Loop, setLoop] = useState();
  const [SearchWords, setSearchWords] = useState("");
  const [isfocus, setisfocus] = useState(false);
  const [SearchData, setSearchData] = useState([]);
  const [theme, settheme] = useState("light");
  const [halving, sethalving] = useState(null);
  const { AllCoinsData, SumMarketCap, SumVolume, CoinsCount } =
    useContext(CoinsContext);

  const navigate = useNavigate();

  const handleDropdown = (index) => {
    setActiveIndex(index);
  };

  useEffect(() => {
    // FetchGainersCoins();

    axios(process.env.REACT_APP_SERVER_URL + "/api/halving-times").then(
      (res) => {
        if (res.status == 200) {
          sethalving(res.data.data[0]["attributes"]["time"]);
        }
      }
    );
    if (localStorage.getItem("theme")) {
      if (localStorage.getItem("theme") == "is_dark") {
        settheme("dark");
      } else {
        settheme("light");
      }
    }
  }, [AllCoinsData]);

  useEffect(() => {
    setLoop();
    // setInterval(() => {
    //   FetchGainersCoins();
    // }, 1000000)
  });

  // const FetchGainersCoins = () => {
  //   let sum_market_cap = 0;
  //   let sum_volumes = 0;

  //   AllCoinsData.forEach((ele) => {
  //     if (ele.attributes.market_cap) {
  //       sum_market_cap += parseFloat(ele.attributes.market_cap);
  //       sum_volumes += parseFloat(ele.attributes.volume_24);
  //     }
  //   });

  //   setSumMarketCap(
  //     new Intl.NumberFormat("en-US", {
  //       style: "currency",
  //       currency: "USD",
  //     })
  //       .format(Math.round(sum_market_cap))
  //       .toString()
  //       .split(".")[0]
  //   );

  //   setSumVolume(
  //     new Intl.NumberFormat("en-US", {
  //       style: "currency",
  //       currency: "USD",
  //     })
  //       .format(Math.round(sum_volumes))
  //       .toString()
  //       .split(".")[0]
  //   );
  // };

  function HandleSearch(search) {
    if (search.length > 0) {
      axios(process.env.REACT_APP_PROXY_URL + "/coins/" + search).then(
        (res) => {
          if (res.status === 200) {
            setSearchData(res.data);
          }
        }
      );

      // let data = {};
      // let filtered_coins = AllCoins.filter((e) =>
      //   e.attributes.name.toLowerCase().includes(search.toLowerCase())
      // );
      // if (filtered_coins.length > 0) {
      //   filtered_coins.forEach((ele) => {
      //     ele.attributes.tables.data.forEach((table) => {
      //       if (data[table.attributes.name]) {
      //         data[table.attributes.name].push(ele);
      //       } else {
      //         data[table.attributes.name] = [];
      //         data[table.attributes.name].push(ele);
      //       }
      //     });
      //   });
      // }

      // console.log(data);
      // setSearchData(data);
    } else {
      setSearchData([]);
    }
  }

  return (
    <header
      id="header_main"
      className={` `}
      style={{ height: "50px", paddingTop: "10px" }}
    >
      <div className="container-fluid mobile_sm_padding">
        <div className="row">
          <div className="col-12 mobile_sm_padding">
            <div
              className="header__body d-flex justify-content-between"
              style={{ paddingLeft: "10px" }}
            >
              <div className="header__left header_left_stats">
                <div
                  style={{
                    display: "flex",
                    gap: "20px",
                    overflowX: "auto",
                    alignItems: "center",
                  }}
                >
                  <div style={{ display: "flex", gap: "5px" }}>
                    <div>Games: </div>
                    <div
                      style={{ color: "rgb(55, 114, 255)", fontWeight: "500" }}
                    >
                      {CoinsCount}
                    </div>
                  </div>
                  <div style={{ display: "flex", gap: "5px" }}>
                    <div style={{ whiteSpace: "nowrap" }}>Market Cap:</div>
                    <div
                      style={{ color: "rgb(55, 114, 255)", fontWeight: "500" }}
                    >
                      {SumMarketCap}
                    </div>
                  </div>
                  <div style={{ display: "flex", gap: "5px" }}>
                    <div style={{ whiteSpace: "nowrap" }}>24H Volume:</div>
                    <div
                      style={{ color: "rgb(55, 114, 255)", fontWeight: "500" }}
                    >
                      {" "}
                      {SumVolume}
                    </div>
                  </div>
                  <div style={{ display: "flex", gap: "5px" }}>
                    <div style={{ whiteSpace: "nowrap" }}>Bitcoin Halving:</div>
                    <div
                      style={{
                        color: "rgb(55, 114, 255)",
                        fontWeight: "500",
                        whiteSpace: "nowrap",
                      }}
                    >
                      {" "}
                      {halving != null && (
                        <Countdown date={halving} renderer={CountdownDisplay} />
                      )}
                    </div>
                  </div>
                </div>
              </div>

              <div className="header__right" style={{ width: "35%" }}>
                <form
                  className={
                    theme == "dark" ? "search_form search_dark" : "search_form"
                  }
                  action="#"
                  style={{ position: "relative", width: "100%" }}
                >
                  <div
                    className={
                      theme == "dark"
                        ? "form-control search_dark"
                        : "form-control"
                    }
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "10px",
                      border: "none",
                      borderRadius: "10px",
                      background: "#f7f9ff",
                    }}
                  >
                    <i
                      class={
                        theme == "dark"
                          ? "fa-solid fa-magnifying-glass search_icon_dark"
                          : "fa-solid fa-magnifying-glass"
                      }
                    ></i>

                    <input
                      className={
                        theme == "dark"
                          ? "search_input search_dark search_icon_dark"
                          : "search_input"
                      }
                      autoComplete="off"
                      id="search_input"
                      type="text"
                      onFocus={() => {
                        setisfocus(true);
                      }}
                      // onBlur={() => {
                      //   setisfocus(false);
                      // }}
                      placeholder="Find your game"
                      onChange={(e) => {
                        HandleSearch(e.target.value);
                        setSearchWords(e.target.value);
                      }}
                      style={{ border: "0px" }}
                    />
                  </div>

                  <div
                    className={
                      theme == "dark"
                        ? "search_list search_dark"
                        : "search_list"
                    }
                    id="search_form"
                    style={{
                      display: isfocus ? "flex" : "none",
                    }}
                  >
                    {Object.keys(SearchData).map((table) => {
                      return (
                        <>
                          <div>{table}</div>
                          {SearchData[table].map((ele, i) => {
                            return (
                              <a
                                href={`/games/${ele.attributes.slug}`}
                                className="search_result"
                                id="search_result"
                                key={i}
                                style={{
                                  display: "flex",
                                  gap: "10px",
                                  alignItems: "center",
                                  padding: "10px",
                                  cursor: "pointer",
                                  transition: "0.3s",
                                }}
                              >
                                <div>
                                  <img
                                    id="search_img"
                                    style={{
                                      width: "30px",
                                      height: "30px",
                                      borderRadius: "50%",
                                    }}
                                    src={
                                      ele.attributes.icon_url?.length > 0
                                        ? ele.attributes.icon_url
                                        : process.env.REACT_APP_SERVER_URL +
                                          ele.attributes.icon.data.attributes
                                            .url
                                    }
                                    alt=""
                                  />
                                </div>
                                <div id="search_name">
                                  {ele.attributes.name}
                                </div>
                                <div
                                  id="search_ticker"
                                  style={{ color: "gray", fontSize: "13px" }}
                                >
                                  {ele.attributes.ticker}
                                </div>
                              </a>
                            );
                          })}
                        </>
                      );
                    })}
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default StatsHeader;
