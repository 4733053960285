import React, { useState, useEffect, useContext } from "react";
import "./styles.scss";
import { Link } from "react-router-dom";

import img from "../../assets/images/logo/log-footer.png";
import img1 from "../../assets/images/logo/logo-footer-dark.png";
import Sale01 from "../sale/Sale01";
import { LoginContext } from "../LoginContext";

function Footer(props) {
  const [productLink] = useState([
    {
      title: "Investing",
      path: "/investing",
    },
    {
      title: "Earning",
      path: "/earning",
    },
  ]);
  const [servicesLink] = useState([
    // {
    //   title: "Whitepaper",
    //   path: "https://docs.playstoken.com/",
    // },
    {
      title: "Blog",
      path: "/blog",
    },
    {
      title: "Terms of Use",
      path: "terms-conditions",
    },
    {
      title: "Privacy Policy",
      path: "privacy-policy",
    },
  ]);

  const [listSocial] = useState([
    {
      size: "20px",
      icon: "fa-brands fa-discord",
      path: "#",
    },
    {
      size: "24px",
      icon: "icon-twitter",
      path: "#",
    },
    {
      size: "20px",
      icon: "fa-solid fa-file",
      path: "#",
    },
  ]);

  const [isVisible, setIsVisible] = useState(false);

  const { theme } = useContext(LoginContext);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    const toggleVisibility = () => {
      if (window.pageYOffset > 500) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    window.addEventListener("scroll", toggleVisibility);

    return () => window.removeEventListener("scroll", toggleVisibility);
  }, []);

  const subscribe = () => {
    alert("Thank you for subscribing! Please check your email!");
  };

  return (
    <div style={{ width: "100%", bottom: "0" }}>
      <Sale01 />
      <footer className="footer style-2">
        <div className="container">
          <div className="footer__main">
            <div className="row">
              <div className="col-xl-4 col-md-6">
                <div className="info">
                  <Link to="/" className="logo light">
                    <img src={img} alt="" />
                  </Link>
                  <Link to="/" className="logo dark">
                    <img src={img1} alt="" />
                  </Link>
                  <h6>Let's game! 🤙</h6>
                  <ul className="list">
                    <li>
                      <p>
                        PlaystoEarn is a Web3 Gaming portal where you can find
                        the latest games!
                      </p>
                    </li>
                    <li>
                      <p>support@playstoearn.com</p>
                    </li>
                    {/* <li>
                      <p>
                        A{" "}
                        <Link to="https://www.playsrare.com/">
                          PlaysRare Ecosystem
                        </Link>{" "}
                        Product
                      </p>
                    </li> */}
                  </ul>
                </div>
              </div>
              <div className="col-xl-4 col-md-6">
                <div className="widget">
                  <div className="widget-link">
                    <h6 className="title">LISTS</h6>
                    <ul>
                      {productLink.map((data, idx) => (
                        <li key={idx}>
                          <a href={data.path}>{data.title}</a>
                        </li>
                      ))}
                    </ul>
                  </div>
                  <div className="widget-link s2">
                    <h6 className="title">INFORMATION</h6>
                    <ul>
                      {servicesLink.map((data, idx) => (
                        <li key={idx}>
                          <Link
                            target={data.title == "Whitepaper" ? "_blank" : ""}
                            to={data.path}
                          >
                            {data.title}
                          </Link>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-xl-4 col-md-12">
                <div className="footer-contact">
                  <h5>Newletter</h5>
                  <p>
                    Subscribe to our newsletter for the latest Web3 game
                    releases
                  </p>
                  <iframe
                    name="dummyframe"
                    id="dummyframe"
                    style={{ display: "none" }}
                  ></iframe>
                  <form
                    id="sib-form"
                    method="POST"
                    action="https://8f061310.sibforms.com/serve/MUIFAMKtFdBtECknE2f1r1GVwtujunun9z1nglVg4ZOX_3dGKaBifHkOgVCPsXTbnGPV-H1vhjrMtXwUEDM07rsfll5CCbUS5-a6Sz_7c7uzMLi9KkX6Ah9ua4UT9fOLYYciF14ZXxUONjvda4QKEJbIz68NbmhhoaRSZIXWv_WYOtVycDp-1mXC_5k4OHfdbqh0dH_xT9TgrUlV"
                    data-type="subscription"
                    target="dummyframe"
                  >
                    <input
                      type="email"
                      name="EMAIL"
                      id="EMAIL"
                      placeholder="Enter your email"
                      required=""
                      style={{
                        background: theme == "light" ? "white" : "#222630",
                        color: theme == "light" ? "black" : "white",
                      }}
                    />
                    <button
                      type="submit"
                      className="btn-action"
                      form="sib-form"
                      onClick={subscribe}
                    >
                      Submit
                    </button>
                  </form>
                  <ul className="list-social" style={{ alignItems: "center" }}>
                    {listSocial.map((data, idx) => (
                      <li key={idx}>
                        <a target="_blank" to={data.path}>
                          <span
                            style={{ color: "#777e90", fontSize: data.size }}
                            className={data.icon}
                          ></span>
                        </a>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container-fluid">
          <div className="footer__bottom">
            <p>
              ©2024 <Link to="/">PlaystoEarn</Link> | All rights reserved
            </p>
          </div>
        </div>

        {isVisible && (
          <Link onClick={scrollToTop} to="#" id="scroll-top"></Link>
        )}
      </footer>
    </div>
  );
}

export default Footer;
