import HomeOne from "./HomeOne";
import HomeTwo from "./_unused/HomeTwo";
import HomeThree from "./_unused/HomeThree";
import BuyCrypto from "./_unused/BuyCrypto";
import BuyCryptoConfirm from "./_unused/BuyCryptoConfirm";
import BuyCryptoDetails from "./_unused/BuyCryptoDetails";
import Markets from "./Markets";
import SellSelect from "./_unused/SellSelect";
import SellCryptoAmount from "./_unused/SellCryptoAmount";
import SellCryptoConfirm from "./_unused/SellCryptoConfirm";
import SellCryptoDetails from "./_unused/SellCryptoDetails";
import Blog from "./_unused/Blog";
import BlogGrid01 from "./BlogGrid01";
import BlogGrid02 from "./_unused/BlogGrid02";
import BlogList from "./_unused/BlogList";
import BlogDetails from "./BlogDetails";
import UserProfile from "./UserProfile";
import Login from "./Login";
import Register from "./Register";
import Faq from "./_unused/Faq";
import BlogCategory from "./BlogCategory";
import Page404 from "./404";
import CoinDetails from "./CoinDetails";
import ForgetPassword from "./ForgetPassword";
import ResetPassword from "./ResetPassword";
import Terms from "./Terms";
import Privacy from "./Privacy";
import CoinDetailsMain from "./CoinDetailsMain";

const routes = [
  { path: "/", component: <HomeOne /> },
  { path: "/games/:id", component: <CoinDetailsMain /> },
  { path: "/404", component: <Page404 /> },
  // { path: "/home-v2", component: <HomeTwo /> },
  // { path: "/home-v3", component: <HomeThree /> },
  // { path: "/buy-crypto-select", component: <BuyCrypto /> },
  // { path: "/buy-crypto-confirm", component: <BuyCryptoConfirm /> },
  // { path: "/buy-crypto-details", component: <BuyCryptoDetails /> },
  { path: "/:table", component: <Markets /> },
  // { path: "/sell-select", component: <SellSelect /> },
  // { path: "/sell-crypto-amount", component: <SellCryptoAmount /> },
  // { path: "/sell-crypto-confirm", component: <SellCryptoConfirm /> },
  // { path: "/sell-crypto-details", component: <SellCryptoDetails /> },
  // { path: "/blog-default", component: <Blog /> },
  { path: "/category/:id", component: <BlogCategory /> },
  { path: "/blog", component: <BlogGrid01 /> },
  { path: "/terms-conditions", component: <Terms /> },
  { path: "/privacy-policy", component: <Privacy /> },
  // { path: "/blog-grid-v2", component: <BlogGrid02 /> },
  // { path: "/blog-list", component: <BlogList /> },
  { path: "/blog/:id", component: <BlogDetails /> },
  { path: "/user-profile", component: <UserProfile /> },
  { path: "/login", component: <Login /> },
  { path: "/forget-password", component: <ForgetPassword /> },
  { path: "/reset-password/:id", component: <ResetPassword /> },
  { path: "/register", component: <Register /> },
  // { path: "/contact", component: <Contact /> },
  // { path: "/faq", component: <Faq /> },
];

export default routes;
