const menus = [
  {
    id: 1,
    name: "Home",
    links: "/",
  },
  {
    id: 2,
    name: "Investing",
    links: "/investing",
  },
  {
    id: 3,
    name: "Earning",
    links: "/earning",
  },
  // {
  //   id: 4,
  //   name: "Whitepaper",
  //   links: "https://docs.playstoken.com/",
  // },
  {
    id: 4,
    name: "Blog",
    links: "/blog",
  },
];

export default menus;
