import React, { useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import img1 from "../../assets/images/coin/btc.png";
import img2 from "../../assets/images/coin/eth.png";
import img3 from "../../assets/images/coin/tet.png";
import img4 from "../../assets/images/coin/bnb.png";

import "./styles.scss";

import { Link, useNavigate } from "react-router-dom";
import Loader from "../Loader";
import axios from "axios";
import { LoginContext } from "../LoginContext";
import { CoinsContext } from "../CoinsContext";

Crypto01.propTypes = {};

function Crypto01({}) {
  const [dataCrytoTab] = useState([
    {
      id: 1,
      title: "Crypto",
    },
    {
      id: 2,
      title: "DeFi",
    },
    {
      id: 3,
      title: "BSC",
    },
    {
      id: 4,
      title: "NFT",
    },
    {
      id: 5,
      title: "Metaverse",
    },
    {
      id: 6,
      title: "Polkadot",
    },
    {
      id: 7,
      title: "Solana",
    },
    {
      id: 8,
      title: "Opensea",
    },
    {
      id: 9,
      title: "Makersplace",
    },
  ]);

  const [Tables, setTables] = useState([]);
  const [TablesData, setTablesData] = useState([]);
  const [Columns, setColumns] = useState([]);
  const [Coins, setCoins] = useState([]);
  const [AllCoins, setAllCoins] = useState([]);
  const [TableCoins, setTableCoins] = useState([]);
  const [CurrentTable, setCurrentTable] = useState("");
  const [is_trade, setis_trade] = useState(null);
  const [ColumnsConversions, setColumnsConversions] = useState({});
  const [BlockChains, setBlockChains] = useState({});
  const [Platforms, setPlatforms] = useState({});

  const [Loop, setLoop] = useState();
  const [FetchLoop, setFetchLoop] = useState();
  const [TableSlug, setTableSlug] = useState("");
  const [isLoading, setisLoading] = useState(true);
  const [RecentCoins, setRecentCoins] = useState([]);

  const { theme } = useContext(LoginContext);

  const { SumMarketCap, SumVolume, GainersCoins } = useContext(CoinsContext);

  const navigate = useNavigate();

  function sort(arr) {
    return arr.sort(function (a, b) {
      if (a.attributes.h_24 === b.attributes.h_24) return 0;
      return b.attributes.h_24 - a.attributes.h_24;
    });
  }

  useEffect(() => {
    // FetchGainersCoins();
    axios(
      process.env.REACT_APP_SERVER_URL + `/api/blockchains?populate=*`
    ).then((res) => {
      if (res.status == 200) {
        if (res.data.data.length > 0) {
          let chains = {};

          res.data.data.forEach((ele) => {
            chains[ele.attributes.title] =
              ele.attributes.icon.data.attributes.url;
          });

          setBlockChains(chains);
        }
      }
    });
    axios(process.env.REACT_APP_SERVER_URL + `/api/platforms?populate=*`).then(
      (res) => {
        if (res.status == 200) {
          if (res.data.data.length > 0) {
            let data = {};

            res.data.data.forEach((ele) => {
              data[ele?.attributes?.title] =
                ele.attributes.icon.data[0].attributes.url;
            });

            setPlatforms(data);
          }
        }
      }
    );

    FetchRecentCoins();
  }, []);

  let myinterval;

  useEffect(() => {
    setLoop();
    // setInterval(() => {
    //   FetchGainersCoins();
    // }, 1000000)
  }, [CurrentTable]);

  const FetchRecentCoins = () => {
    axios(process.env.REACT_APP_PROXY_URL + `/getRecentCoins`).then((res) => {
      setRecentCoins(res.data.data);
    });
  };

  // const FetchGainersCoins = () => {
  //   axios(
  //     process.env.REACT_APP_SERVER_URL +
  //       `/api/coins?fields[0]=name&filters[coin_id][$ne]=null`
  //   ).then(async (res) => {
  //     let temp_coins = res.data.data;

  //     temp_coins.sort(function (a, b) {
  //       return a.id - b.id;
  //     });

  //     setGainersCoins(sort(temp_coins).slice(0, 4));
  //   });
  // };

  // const HandleTableChange = (table) => {
  //   clearInterval(Loop);
  //   clearInterval(FetchLoop);
  //   setisLoading(true);
  //   setCurrentTable(table);
  //   setCoins([]);
  //   setColumns([]);
  //   FetchCoins(table["value"]);
  //   setFetchLoop(
  //     setInterval(() => {
  //       FetchCoins(table["value"]);
  //     }, 1000000)
  //   );
  // };

  const RatingClassColor = (rating) => {
    if (rating >= 7) {
      return "rating green";
    } else if (rating >= 3) {
      return "rating yellow";
    } else if (rating >= 0) {
      return "rating red";
    }
  };

  return (
    <section
      className="crypto"
      data-aos="fade-up"
      data-aos-duration="1000"
      style={{ marginTop: "20px" }}
    >
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div
              className=""
              style={{
                boxShadow: "unset",
                background: theme == "light" ? "white" : "#141416",
              }}
            >
              <div
                className="stats_crypto"
                style={{ display: "flex", gap: "10px" }}
              >
                <div
                  className={`crypto-box active`}
                  style={{
                    width: "100%",
                    overflow: "auto",
                    background: theme == "light" ? "white" : "#18191d",
                  }}
                >
                  <div
                    style={{ display: "flex", gap: "5px", fontSize: "20px" }}
                  >
                    <div style={{ color: "#3772ff" }}>
                      <i class="fa-solid fa-clock-rotate-left"></i>
                    </div>
                    <div
                      style={{
                        color: theme == "light" ? "black" : "white",
                        fontWeight: "500",
                      }}
                    >
                      Recently Added
                    </div>
                  </div>

                  <table
                    style={{ width: "100%", marginTop: "20px" }}
                    className="table_sp"
                  >
                    <thead>
                      <tr>
                        <th>Token</th>
                        <th style={{ textAlign: "right" }} colSpan={"2"}>
                          Date Added
                        </th>
                      </tr>
                    </thead>

                    <tbody>
                      {RecentCoins?.map((ele, i) => {
                        let date = new Date(ele.attributes.createdAt);
                        let month = date.toLocaleString("default", {
                          month: "short",
                        });
                        let day = date.getDate();
                        let year = date.getFullYear();

                        let full_date = `${month} ${day}`;

                        return (
                          <tr
                            className="coin_table_tr"
                            onClick={() => {
                              if (ele.attributes.tables.data) {
                                navigate("/games/" + ele.attributes.slug);
                              }
                            }}
                          >
                            <td
                              style={{
                                color: theme == "light" ? "black" : "white",
                              }}
                            >
                              {i + 1}
                            </td>
                            <td>
                              <div
                                style={{ display: "flex", gap: "5px" }}
                                to="#"
                              >
                                <img
                                  style={{
                                    width: "30px",
                                    height: "30px",
                                    borderRadius: "50%",
                                  }}
                                  src={
                                    ele.attributes?.icon_url?.length > 0
                                      ? ele.attributes?.icon_url
                                      : process.env.REACT_APP_SERVER_URL +
                                        ele.attributes?.icon?.data?.attributes
                                          ?.url
                                  }
                                  alt="rockie"
                                />
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                  }}
                                >
                                  <span
                                    style={{
                                      fontWeight: "bold",
                                      fontSize: "16px",
                                      color:
                                        theme == "light" ? "black" : "white",
                                    }}
                                  >
                                    {ele.attributes.name}
                                  </span>
                                  <span
                                    style={{
                                      fontSize: "12px",
                                      color: "gray",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {ele.attributes.ticker}
                                  </span>
                                </div>
                              </div>
                            </td>
                            <td>
                              <div
                                style={{
                                  textAlign: "right",
                                  whiteSpace: "nowrap",
                                  color: theme == "light" ? "black" : "white",
                                }}
                              >
                                {full_date}
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
                <div
                  className={`crypto-box active`}
                  style={{
                    width: "100%",
                    overflow: "auto",
                    background: theme == "light" ? "white" : "#18191d",
                  }}
                >
                  <div
                    style={{ display: "flex", gap: "5px", fontSize: "20px" }}
                  >
                    <div style={{ color: "#3772ff" }}>
                      <i class="fa-solid fa-chart-line"></i>
                    </div>
                    <div
                      style={{
                        color: theme == "light" ? "black" : "white",
                        fontWeight: "500",
                      }}
                    >
                      Biggest Gainers
                    </div>
                  </div>

                  <table
                    style={{ width: "100%", marginTop: "20px" }}
                    className="table_sp"
                  >
                    <thead>
                      <tr>
                        <th>Token</th>
                        <th style={{ textAlign: "right" }} colSpan={"2"}>
                          24H
                        </th>
                      </tr>
                    </thead>

                    <tbody>
                      {GainersCoins.map((ele, i) => {
                        let date = new Date(ele.attributes.createdAt);
                        let month = date.toLocaleString("default", {
                          month: "short",
                        });
                        let day = date.getDate();
                        let year = date.getFullYear();

                        let full_date = `${month} ${day}`;
                        console.log("GainersCoins", ele);

                        return (
                          <tr
                            className="coin_table_tr"
                            onClick={() => {
                              navigate("/games/" + ele.attributes.slug);
                            }}
                          >
                            <td
                              style={{
                                color: theme == "light" ? "black" : "white",
                              }}
                            >
                              {i + 1}
                            </td>
                            <td>
                              <div
                                style={{ display: "flex", gap: "5px" }}
                                to="#"
                              >
                                <img
                                  style={{
                                    width: "30px",
                                    height: "30px",
                                    borderRadius: "50%",
                                  }}
                                  src={
                                    ele.attributes?.icon_url?.length > 0
                                      ? ele.attributes?.icon_url
                                      : process.env.REACT_APP_SERVER_URL +
                                        ele.attributes?.icon?.data?.attributes
                                          ?.url
                                  }
                                  alt="rockie"
                                />
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                  }}
                                >
                                  <span
                                    style={{
                                      fontWeight: "bold",
                                      fontSize: "16px",
                                      color:
                                        theme == "light" ? "black" : "white",
                                    }}
                                  >
                                    {ele.attributes.name}
                                  </span>
                                  <span
                                    style={{
                                      fontSize: "12px",
                                      color: "gray",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {ele.attributes.ticker}
                                  </span>
                                </div>
                              </div>
                            </td>
                            <td>
                              <div
                                style={{
                                  textAlign: "right",
                                  color: "rgb(88, 189, 125)",
                                  display: "flex",
                                  alignItems: "center",
                                  gap: "5px",
                                  justifyContent: "flex-end",
                                }}
                              >
                                <i class="fa-solid fa-angle-up"></i>
                                <div>{ele.attributes.h_24.toFixed(2)}%</div>
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "10px",
                    width: "100%",
                  }}
                >
                  <div
                    className={`crypto-box active`}
                    style={{
                      width: "100%",
                      height: "100%",
                      overflow: "auto",
                      background: theme == "light" ? "white" : "#18191d",
                    }}
                  >
                    <div
                      style={{ display: "flex", gap: "5px", fontSize: "20px" }}
                    >
                      <div style={{ color: "#3772ff" }}>
                        <i class="fa-solid fa-chart-column"></i>
                      </div>
                      <div
                        style={{
                          color: theme == "light" ? "black" : "white",
                          fontWeight: "500",
                          whiteSpace: "nowrap",
                        }}
                      >
                        Gaming Market Cap
                      </div>
                    </div>

                    <br />

                    <h4 style={{ color: "rgb(55, 114, 255)" }}>
                      {SumMarketCap}
                    </h4>
                  </div>
                  <div
                    className={`crypto-box active`}
                    style={{
                      width: "100%",
                      height: "100%",
                      overflow: "auto",
                      background: theme == "light" ? "white" : "#18191d",
                    }}
                  >
                    <div
                      style={{ display: "flex", gap: "5px", fontSize: "20px" }}
                    >
                      <div style={{ color: "#3772ff" }}>
                        <i class="fa-solid fa-hourglass-end"></i>
                      </div>
                      <div
                        style={{
                          color: theme == "light" ? "black" : "white",
                          fontWeight: "500",
                        }}
                      >
                        24h Volume
                      </div>
                    </div>

                    <br />

                    <h4 style={{ color: "rgb(55, 114, 255)" }}>{SumVolume}</h4>
                  </div>
                </div>
              </div>

              <br />
              <br />
              <br />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Crypto01;
