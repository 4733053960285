import React, { useState, useEffect, useLayoutEffect, useContext } from "react";

import Banner02 from "../components/banner/Banner02";
import Crypto01 from "../components/crypto/Crypto01";
import Coinlist01 from "../components/coinlist/Coinlist01";
import Work01 from "../components/work/Work01";
import Testimonial01 from "../components/testimonial/Testimonial01";
import dataPartner from "../assets/fake-data/data-partner";
import dataTestimonial from "../assets/fake-data/data-testimonial";
import dataWork from "../assets/fake-data/data-work";
import dataCoin from "../assets/fake-data/data-coin";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Crypto02 from "../components/crypto/Crypto02";
import PageHead from "../components/pagetitle/PageHead";
import Sale01 from "../components/sale/Sale01";
import { CoinsContext } from "../components/CoinsContext";

function HomeOne(props) {
  const [Tables, setTables] = useState([]);
  const [TablesData, setTablesData] = useState([]);
  const [Columns, setColumns] = useState([]);
  const [Coins, setCoins] = useState([]);
  const [AllCoins, setAllCoins] = useState([]);
  const [TableCoins, setTableCoins] = useState([]);
  const [CurrentTable, setCurrentTable] = useState("");
  const [is_trade, setis_trade] = useState(null);
  const [ColumnsConversions, setColumnsConversions] = useState({});
  const [BlockChains, setBlockChains] = useState({});
  const [Platforms, setPlatforms] = useState({});

  const [Loop, setLoop] = useState();
  const [FetchLoop, setFetchLoop] = useState();
  const [TableSlug, setTableSlug] = useState("");
  const [isLoading, setisLoading] = useState(true);

  const { AllCoinsData } = useContext(CoinsContext);

  const [PreRenderCoins, setPreRenderCoins] = useState({});

  function sort(arr, type) {
    if (type == true) {
      return arr.sort(function (a, b) {
        if (a.attributes.market_cap === b.attributes.market_cap) return 0;
        return b.attributes.market_cap - a.attributes.market_cap;
      });
    } else {
      return arr.sort(function (a, b) {
        if (a.attributes.earnings_rating == b.attributes.earnings_rating)
          return 0;

        console.log(a.attributes.earnings_rating, b.attributes.earnings_rating);
        return b.attributes.earnings_rating - a.attributes.earnings_rating;
      });
    }
  }

  useEffect(() => {
    axios(
      process.env.REACT_APP_SERVER_URL + `/api/tables?populate=columns`
    ).then(async (res) => {
      if (res.status == 200) {
        let tables = [];

        if (res.data.data.length > 0) {
          res.data.data.forEach((ele) => {
            tables.push({ label: ele.attributes.name, value: ele.id });
          });
          setTablesData(res.data.data);
          setTables(tables);
          setCurrentTable(tables[0]);
          setis_trade(res.data.data[0].attributes.is_trade);

          setTableSlug(res.data.data[0].attributes.slug);
          setColumns(res.data.data[0].attributes.columns.data);

          let pre_render_coins = {};

          for (let i = 0; i < res.data.data.length; i++) {
            const element = res.data.data[i];

            let coins = AllCoinsData.filter(
              (item) =>
                Array.isArray(item.attributes.tables?.data) &&
                item.attributes.tables.data.some((table) => table.id === 1)
            );

            coins.sort(function (a, b) {
              return a.id - b.id;
            });
            let cols_w_trust =
              element.attributes.columns.data.filter(
                (e) => e.attributes.name == "Trust Rating"
              ).length > 0
                ? true
                : false;

            pre_render_coins[element.id] = sort(coins, cols_w_trust);
          }

          setPreRenderCoins(pre_render_coins);

          let coins = AllCoinsData.filter(
            (item) =>
              Array.isArray(item.attributes.tables?.data) &&
              item.attributes.tables.data.some((table) => table.id === 1)
          );

          coins.sort(function (a, b) {
            return a.id - b.id;
          });
          let cols_w_trust =
            res.data.data[0].attributes.columns.data.filter(
              (e) => e.attributes.name == "Trust Rating"
            ).length > 0
              ? true
              : false;
          setCoins(sort(coins, cols_w_trust));

          axios(
            process.env.REACT_APP_SERVER_URL + "/api/columns-conversions"
          ).then((conversions_res) => {
            if (conversions_res.status == 200) {
              if (conversions_res.data.data.length > 0) {
                let columns_conversions = {};

                conversions_res.data.data.forEach((ele) => {
                  columns_conversions[ele.attributes.label] =
                    ele.attributes.value;
                });
                setisLoading(false);
                setColumnsConversions(columns_conversions);
              }
            }
          });
        }
      }

      axios(
        process.env.REACT_APP_SERVER_URL + `/api/blockchains?populate=*`
      ).then((res) => {
        if (res.status == 200) {
          if (res.data.data.length > 0) {
            let chains = {};

            res.data.data.forEach((ele) => {
              chains[ele.attributes.title] =
                ele.attributes.icon.data.attributes.url;
            });

            setBlockChains(chains);
          }
        }
      });
      axios(
        process.env.REACT_APP_SERVER_URL + `/api/platforms?populate=*`
      ).then((res) => {
        if (res.status == 200) {
          if (res.data.data.length > 0) {
            let data = {};

            res.data.data.forEach((ele) => {
              data[ele?.attributes?.title] =
                ele.attributes.icon.data[0].attributes.url;
            });

            setPlatforms(data);
          }
        }
      });
    });
  }, [AllCoinsData]);

  let myinterval;

  useEffect(() => {
    setLoop();
    // setInterval(() => {
    //   FetchCoins(CurrentTable["value"]);
    // }, 1000000)
  }, [CurrentTable]);

  const FetchCoins = (table_id) => {
    if (table_id) {
      axios(
        process.env.REACT_APP_SERVER_URL +
          `/api/tables?filters[id][$eq]=${table_id}&populate=columns`
      ).then(async (res) => {
        if (res.status == 200) {
          let tables = [];

          if (res.data.data.length > 0) {
            setTableSlug(res.data.data[0].attributes.slug);
            setColumns(res.data.data[0].attributes.columns.data);
            setis_trade(res.data.data[0].attributes.is_trade);

            console.log("PreRenderCoins", PreRenderCoins);

            setCoins(PreRenderCoins[table_id]);

            // axios(
            //   process.env.REACT_APP_SERVER_URL +
            //     `/api/coins?filters\[tables\][id][$eq]=${res.data?.data[0].id}&pagination[limit]=20&populate=*`
            // ).then(({ data }) => {
            //   const temp_coins = data.data;

            //   setTableCoins(temp_coins);

            //   let coins_comma = [];

            //   temp_coins.map((ele) => {
            //     if (ele.attributes.coin_id) {
            //       coins_comma.push(ele.attributes.coin_id);
            //     }
            //   });

            //   axios(
            //     coins_comma.length > 0
            //       ? process.env.REACT_APP_PROXY_URL +
            //           "/api/" +
            //           coins_comma.join(",")
            //       : process.env.REACT_APP_PROXY_URL + "/api/5"
            //   ).then(({ data }) => {
            //     console.log("comma", data);

            //     let coins = [];

            //     temp_coins.forEach((ele) => {
            //       const coin_api = data.data[ele.attributes.coin_id];
            //       if (coin_api) {
            //         if (ele.attributes["d_7"] == null) {
            //           ele.attributes["d_7"] =
            //             coin_api["quote"]["USD"]["percent_change_7d"];
            //         }
            //         if (ele.attributes["h_24"] == null) {
            //           ele.attributes["h_24"] =
            //             coin_api["quote"]["USD"]["percent_change_24h"];
            //         }
            //         if (ele.attributes["market_cap"] == null) {
            //           ele.attributes["market_cap"] =
            //             coin_api["quote"]["USD"]["market_cap"];
            //         }
            //         if (ele.attributes["name"] == null) {
            //           ele.attributes["name"] = coin_api["name"];
            //         }
            //         if (ele.attributes["price"] == null) {
            //           ele.attributes["price"] =
            //             coin_api["quote"]["USD"]["price"];
            //         }
            //         if (ele.attributes["supply"] == null) {
            //           ele.attributes["supply"] = coin_api["circulating_supply"];
            //         }
            //         if (ele.attributes["volume_24"] == null) {
            //           ele.attributes["volume_24"] =
            //             coin_api["quote"]["USD"]["volume_24h"];
            //         }
            //         coins.push(ele);
            //       } else {
            //         coins.push(ele);
            //       }
            //     });

            //     console.log("endpoint", coins);

            //     axios(
            //       process.env.REACT_APP_SERVER_URL + "/api/columns-conversions"
            //     ).then((conversions_res) => {
            //       if (conversions_res.status == 200) {
            //         if (conversions_res.data.data.length > 0) {
            //           let columns_conversions = {};

            //           conversions_res.data.data.forEach((ele) => {
            //             columns_conversions[ele.attributes.label] =
            //               ele.attributes.value;
            //           });
            //           setisLoading(false);
            //           setColumnsConversions(columns_conversions);

            //           coins.sort(function (a, b) {
            //             return a.id - b.id;
            //           });
            //           let cols_w_trust =
            //             res.data.data[0].attributes.columns.data.filter(
            //               (e) => e.attributes.name == "Trust Rating"
            //             ).length > 0
            //               ? true
            //               : false;
            //           setCoins(sort(coins, cols_w_trust));
            //         }
            //       }
            //     });
            //   });
            // });

            // const coins_api = await Promise.all(coins_promises)
            //   .then((values) => {
            //     return values;
            //   })
            //   .catch((error) => {
            //     this.setState({
            //       loading: false,
            //       error,
            //     });
            //   });
          }
        }
      });
    }
  };

  const HandleTableChange = (table) => {
    clearInterval(Loop);
    clearInterval(FetchLoop);
    setisLoading(true);
    setCurrentTable(table);
    setCoins([]);
    setColumns([]);
    FetchCoins(table["value"]);
    setFetchLoop();
    // setInterval(() => {
    //   FetchCoins(table["value"]);
    // }, 1000000)
  };

  useLayoutEffect(() => {
    document.title = "Best Web3 P2E Blockchain Games";
    document
      .querySelector('meta[name="description"]')
      .setAttribute(
        "content",
        "Find the Best Web3 Play-to-Earn Blockchain Games pricing, earnings, and token information!"
      );
  }, []);

  return (
    <div className="home-1 home-2">
      <Banner02 data={dataPartner} />
      <Sale01 />
      <Crypto02 th />
      <Crypto01 />

      <Coinlist01
        Tables={Tables}
        BlockChains={BlockChains}
        Coins={Coins}
        Columns={Columns}
        ColumnsConversions={ColumnsConversions}
        CurrentTable={CurrentTable}
        HandleTableChange={HandleTableChange}
        Platforms={Platforms}
        TableSlug={TableSlug}
        is_trade={is_trade}
        dataCoin={dataCoin}
      />

      <Work01 data={dataWork} />

      <Testimonial01 data={dataTestimonial} />
    </div>
  );
}

export default HomeOne;
