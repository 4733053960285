import { React, useEffect, useState } from "react";
import AOS from "aos";
import { Navigate, Route, Routes } from "react-router-dom";
import Header from "./components/header";
import Footer from "./components/footer";
import "../src/assets/font/font-awesome.css";
import routes from "./pages";
import Page404 from "./pages/404";
import StatsHeader from "./components/header/StatsHeader";
import { LoginContext } from "./components/LoginContext";
import axios, { all } from "axios";
import { CoinsContext } from "./components/CoinsContext";

function App() {
  const [isLoggedin, setisLoggedin] = useState(null);
  const [theme, settheme] = useState("light");
  const [AllCoinsData, setAllCoins] = useState([]);

  const [User, setUser] = useState(null);
  const [SumMarketCap, setSumMarketCap] = useState("");
  const [SumVolume, setSumVolume] = useState("");
  const [CoinsCount, setCoinsCount] = useState("");
  const [GainersCoins, setGainersCoins] = useState([]);
  const [AdvertedCoins, setAdvertedCoins] = useState([]);
  const [TopAdvertsCoins, setTopAdvertsCoins] = useState([]);
  const [TopAdvertsCoins_earn, setTopAdvertsCoins_earn] = useState([]);

  const [InvestingCoins, setInvestingCoins] = useState([]);
  const [EarningCoins, setEarningCoins] = useState([]);

  useEffect(() => {
    if (localStorage.getItem("jwt")) {
      axios(process.env.REACT_APP_SERVER_URL + "/api/users/me?populate=*", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("jwt"),
        },
      })
        .then((res) => {
          if (res.status == 200) {
            setisLoggedin(true);
            setUser({
              username: res.data.username,
              email: res.data.email,
              img: res.data.img,
              id: res.data.id,
            });
          } else {
            setisLoggedin(false);
          }
        })
        .catch((err) => {
          setisLoggedin(false);
        });
    } else {
      setisLoggedin(false);
    }
    if (localStorage.getItem("theme")) {
      if (localStorage.getItem("theme") == "is_dark") {
        settheme("dark");
      } else {
        settheme("light");
      }
    }
  }, []);

  useEffect(() => {
    AOS.init({
      duration: 2000,
    });
  }, []);

  useEffect(() => {
    axios(process.env.REACT_APP_PROXY_URL + `/getAllCoins`).then(({ data }) => {
      const temp_coins = data.data;

      setSumMarketCap(
        new Intl.NumberFormat("en-US", {
          style: "currency",
          currency: "USD",
        })
          .format(Math.round(data.sum_market_cap))
          .toString()
          .split(".")[0]
      );

      setCoinsCount(data.coinsCount);

      setGainersCoins(data.GainersCoins);
      setAdvertedCoins(data.AdvertsCoins);
      setTopAdvertsCoins(data.TopAdvertsCoins);
      setTopAdvertsCoins_earn(data.TopAdvertsCoins_earn);
      setInvestingCoins(data.InvestingCoins);
      setEarningCoins(data.EarningCoins);

      setSumVolume(
        new Intl.NumberFormat("en-US", {
          style: "currency",
          currency: "USD",
        })
          .format(Math.round(data.sum_volumes))
          .toString()
          .split(".")[0]
      );

      let coins_comma = [];

      temp_coins.map((ele) => {
        if (ele.attributes.coin_id) {
          coins_comma.push(ele.attributes.coin_id);
        }
      });

      axios(
        coins_comma.length > 0
          ? process.env.REACT_APP_PROXY_URL + "/api/" + coins_comma.join(",")
          : process.env.REACT_APP_PROXY_URL + "/api/5"
      ).then(({ data }) => {
        let coins = [];

        temp_coins.forEach((ele) => {
          const coin_api = data.data[ele.attributes.coin_id];

          if (coin_api) {
            if (ele.attributes["d_7"] == null) {
              ele.attributes["d_7"] =
                coin_api["quote"]["USD"]["percent_change_7d"];
            }
            if (ele.attributes["h_24"] == null) {
              ele.attributes["h_24"] =
                coin_api["quote"]["USD"]["percent_change_24h"];
            }
            if (ele.attributes["market_cap"] == null) {
              ele.attributes["market_cap"] =
                coin_api["quote"]["USD"]["market_cap"];
            }
            if (ele.attributes["name"] == null) {
              ele.attributes["name"] = coin_api["name"];
            }
            if (ele.attributes["price"] == null) {
              ele.attributes["price"] = coin_api["quote"]["USD"]["price"];
            }
            if (ele.attributes["supply"] == null) {
              ele.attributes["supply"] = coin_api["circulating_supply"];
            }
            if (ele.attributes["volume_24"] == null) {
              ele.attributes["volume_24"] =
                coin_api["quote"]["USD"]["volume_24h"];
            }
          }

          coins.push(ele);
        });

        console.log("all_coins", coins);

        setAllCoins(coins);
      });
    });
  }, []);

  return (
    <LoginContext.Provider value={{ isLoggedin, User, theme }}>
      <CoinsContext.Provider
        value={{
          AllCoinsData,
          SumMarketCap,
          SumVolume,
          CoinsCount,
          GainersCoins,
          AdvertedCoins,
          InvestingCoins,
          EarningCoins,
          TopAdvertsCoins,
          TopAdvertsCoins_earn,
        }}
      >
        {/* <StatsHeader /> */}
        <Header />

        <Routes>
          {routes.map((data, idx) => (
            <Route key={idx} path={data.path} element={data.component} exact />
          ))}

          <Route path="*" element={<Navigate to="/404" replace />} />
        </Routes>

        <Footer />
      </CoinsContext.Provider>
    </LoginContext.Provider>
  );
}

export default App;
