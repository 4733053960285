import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import MarkdownPreview from "@uiw/react-markdown-preview";
import Loader from "../components/Loader";
import Blog from "../components/blog/Blog";
import BlogCoin from "../components/blog/Blog_coin";
import Crypto04 from "../components/crypto/Crypto04";
import BnB from "../assets/images/icon/bnb_contract.webp";
import Ethereum from "../assets/images/icon/ethereum_contract.webp";
import Energi from "../assets/images/icon/energi_contract.webp";
import Harmony from "../assets/images/icon/harmony_contract.webp";
import { Swiper, SwiperSlide } from "swiper/react";
import Crypto03 from "../components/crypto/Crypto03";
import Contract from "../components/Contract";
import { LoginContext } from "../components/LoginContext";
import { Tooltip as ReactTooltip } from "react-tooltip";

function CoinDetails({ table, id }) {
  const [CoinIcon, setCoinIcon] = useState("");
  const [Coin, setCoin] = useState();
  const [CoinId, setCoinId] = useState("");
  const [BlockChains, setBlockChains] = useState([]);
  const [Platforms, setPlatforms] = useState({});
  const [PlatformsData, setPlatformsData] = useState({});
  const [CryptoPlatforms, setCryptoPlatforms] = useState([]);
  const [BlockChainsData, setBlockChainsData] = useState([]);
  const [ColumnsConversions, setColumnsConversions] = useState({});
  const [ColumnsIcons, setColumnsIcons] = useState({});
  const [ColumnsInfos, setColumnsInfos] = useState({});
  const [Columns, setColumns] = useState([]);
  const [TradeLink, setTradeLink] = useState("");
  const [PlayLink, setPlayLink] = useState("");
  const [Description, setDescription] = useState("");
  const [UpdatedAt, setUpdatedAt] = useState("");
  const [is_trade, setis_trade] = useState(null);
  const [theme, settheme] = useState("light");
  const [isLoading, setisLoading] = useState(true);
  const [ErrorMsg, setErrorMsg] = useState("");
  const [RecentPosts, setRecentPosts] = useState([]);
  const [SimilarCoin, setSimilarCoin] = useState([]);
  const [DiscordSocial, setDiscordSocial] = useState("");
  const [TelegramSocial, setTelegramSocial] = useState("");
  const [TwitterSocial, setTwitterSocial] = useState("");
  const [WebsiteSocial, setWebsiteSocial] = useState("");
  const [FacebookSocial, setFacebookSocial] = useState("");
  const [YoutubeSocial, setYoutubeSocial] = useState("");
  const [MediumSocial, setMediumSocial] = useState("");
  const [RedditSocial, setRedditSocial] = useState("");
  const [Carousel, setCarousel] = useState([]);
  const [CarouselLinks, setCarouselLinks] = useState([]);
  const [CarouselPerPage, setCarouselPerPage] = useState(3);
  const [showscroll, setshowscroll] = useState(false);
  const [iswatch, setiswatch] = useState(false);
  const [watchId, setwatchId] = useState("");
  const [showStats, setshowStats] = useState(null);
  const [is_earn, setis_earn] = useState(false);
  const [is_tokenomics, setis_tokenomics] = useState(false);
  const [heading_title, setheading_title] = useState("");
  const [heading_title_seo, setheading_title_seo] = useState("");
  const [description_seo, setdescription_seo] = useState("");

  const { isLoggedin, User } = useContext(LoginContext);

  function kFormatter(num) {
    return Math.abs(num) > 999999
      ? Math.sign(num) * (Math.abs(num) / 1000000) + "M"
      : Math.sign(num) * Math.abs(num);
  }

  function sort(arr, type) {
    if (type == false) {
      return arr.sort(function (a, b) {
        if (a.attributes.market_cap === b.attributes.market_cap) return 0;
        return b.attributes.market_cap - a.attributes.market_cap;
      });
    } else {
      return arr.sort(function (a, b) {
        if (a.attributes.earnings_rating == b.attributes.earnings_rating)
          return 0;

        console.log(a.attributes.earnings_rating, b.attributes.earnings_rating);
        return b.attributes.earnings_rating - a.attributes.earnings_rating;
      });
    }
  }

  function shuffle(array) {
    let currentIndex = array.length,
      randomIndex;

    // While there remain elements to shuffle.
    while (currentIndex > 0) {
      // Pick a remaining element.
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;

      // And swap it with the current element.
      [array[currentIndex], array[randomIndex]] = [
        array[randomIndex],
        array[currentIndex],
      ];
    }

    return array;
  }

  const navigate = useNavigate();

  useEffect(() => {
    if (table == "earning" && id == "illuvium") {
      setshowStats(false);
    } else if (table == "web-2-games" && id == "genshin-impact") {
      setshowStats(false);
    } else {
      setshowStats(true);
    }
  }, [table, id]);

  useEffect(() => {
    if (!id) return;
    if (window.location.href[window.location.href.length - 1] == "/")
      window.location.href = window.location.href.slice(0, -1);

    console.log("client ", document.body.clientWidth);

    if (document.body.clientWidth >= 600) {
      setCarouselPerPage(3);
    } else {
      setCarouselPerPage(1.5);
    }

    axios(
      process.env.REACT_APP_SERVER_URL +
        `/api/tables?filters[slug][$eq]=${table}`
    ).then((table_data) => {
      if (table_data.status == 200) {
        axios(process.env.REACT_APP_PROXY_URL + `/getCoinSlug/${id}`).then(
          (res) => {
            if (res.status == 200) {
              if (res.data.data.length == 0) {
                navigate("/404");
              }

              setis_earn(table_data.data.data[0].attributes.is_earn);
              setis_tokenomics(
                table_data.data.data[0].attributes.is_tokenomics
              );

              setCoinIcon(
                res.data.data[0].attributes.icon_url?.length > 0
                  ? res.data.data[0].attributes.icon_url
                  : process.env.REACT_APP_SERVER_URL +
                      res.data.data[0].attributes.icon.data.attributes.url
              );

              if (table_data.data.data[0].attributes.is_earn) {
                document
                  .querySelector('meta[name="description"]')
                  .setAttribute(
                    "content",
                    res.data.data[0].attributes.seo_description_earn
                  );
                document.title = res.data.data[0].attributes.seo_title_earn;

                setDescription(
                  res.data.data[0].attributes.description_earn
                    .replaceAll("\\n\\n", "  \n\n")
                    .replaceAll("\\n", "")
                );
                setheading_title(
                  res.data.data[0].attributes.heading_title_earn
                );
              } else {
                document
                  .querySelector('meta[name="description"]')
                  .setAttribute(
                    "content",
                    res.data.data[0].attributes.seo_description
                  );
                document.title = res.data.data[0].attributes.seo_title;

                setDescription(
                  res.data.data[0].attributes.description
                    .replaceAll("\\n\\n", "  \n\n")
                    .replaceAll("\\n", "")
                );
                setheading_title(res.data.data[0].attributes.heading_title);
              }

              if (localStorage.getItem("theme")) {
                if (localStorage.getItem("theme") == "is_dark") {
                  settheme("dark");
                } else {
                  settheme("light");
                }
              }

              let date = new Date(res.data.data[0].attributes.updatedAt);
              let month = date.toLocaleString("default", {
                month: "long",
              });
              let day = date.getDate();
              let year = date.getFullYear();

              let full_date = `${month} ${day}, ${year}`;
              console.log("sstd", res.data.data);
              setTradeLink(res.data.data[0].attributes.trade_link);
              setPlayLink(res.data.data[0].attributes.play_link);
              setDiscordSocial(res.data.data[0].attributes.discord_social);
              setTelegramSocial(res.data.data[0].attributes.telegram_social);
              setTwitterSocial(res.data.data[0].attributes.twitter_social);

              setFacebookSocial(res.data.data[0].attributes.facebook_social);
              setYoutubeSocial(res.data.data[0].attributes.youtube_social);
              setMediumSocial(res.data.data[0].attributes.medium_social);
              setRedditSocial(res.data.data[0].attributes.reddit_social);

              setWebsiteSocial(res.data.data[0].attributes.play_link);
              setUpdatedAt(full_date);

              setBlockChains(res.data.data[0].attributes.blockchains.data);
              setPlatforms(res.data.data[0].attributes.platforms.data);
              setCarousel(res.data.data[0].attributes.carousel.data);
              setCarouselLinks(
                JSON?.parse(res.data.data[0].attributes.carousel_url)
              );
              setCoinId(res.data.data[0].id);
              let contracts_items_len = 0;
              Object.keys(res.data.data[0].attributes)
                .filter((e) => e.includes("contract"))
                .forEach((contract_key) => {
                  if (res.data.data[0].attributes[contract_key]) {
                    contracts_items_len += 1;
                  }
                });

              if (contracts_items_len > 5) {
                setshowscroll(true);
              }
              if (res.data.data[0].attributes.coin_id) {
                axios(
                  process.env.REACT_APP_PROXY_URL +
                    "/api/" +
                    res.data.data[0].attributes.coin_id
                )
                  .then((response) => {
                    if (response.status == 200) {
                      let coin_api =
                        response.data.data[res.data.data[0].attributes.coin_id];
                      if (res.data.data[0].attributes["d_7"] == null) {
                        res.data.data[0].attributes["d_7"] =
                          coin_api["quote"]["USD"]["percent_change_7d"];
                      }
                      if (res.data.data[0].attributes["h_24"] == null) {
                        res.data.data[0].attributes["h_24"] =
                          coin_api["quote"]["USD"]["percent_change_24h"];
                      }
                      if (res.data.data[0].attributes["market_cap"] == null) {
                        res.data.data[0].attributes["market_cap"] =
                          coin_api["quote"]["USD"]["market_cap"];
                      }
                      if (res.data.data[0].attributes["name"] == null) {
                        res.data.data[0].attributes["name"] = coin_api["name"];
                      }
                      if (res.data.data[0].attributes["price"] == null) {
                        res.data.data[0].attributes["price"] =
                          coin_api["quote"]["USD"]["price"];
                      }
                      if (res.data.data[0].attributes["supply"] == null) {
                        res.data.data[0].attributes["supply"] =
                          coin_api["circulating_supply"];
                      }
                      if (res.data.data[0].attributes["volume_24"] == null) {
                        res.data.data[0].attributes["volume_24"] =
                          coin_api["quote"]["USD"]["volume_24h"];
                      }
                      res.data.data[0].attributes["max_supply"] =
                        coin_api["max_supply"];
                      res.data.data[0].attributes["total_supply"] =
                        coin_api["total_supply"];
                      res.data.data[0].attributes["fully_diluted_market_cap"] =
                        coin_api["quote"]["USD"]["fully_diluted_market_cap"];

                      setCoin(res.data.data[0].attributes);
                      setisLoading(false);
                    }
                  })
                  .catch((err) => {
                    setisLoading(false);

                    setErrorMsg("Something went wrong");
                  });
              } else {
                setshowscroll(false);
                setCoin(res.data.data[0].attributes);
                setisLoading(false);
              }
            }
          }
        );
      }
    });

    axios(
      process.env.REACT_APP_SERVER_URL +
        `/api/tables?filters[slug][$eq]=${table}`
    ).then(async (res) => {
      if (res.status == 200) {
        let tables = [];

        if (res.data.data.length > 0) {
          axios(
            process.env.REACT_APP_PROXY_URL + `/getCoinTableSlug/${table}`
          ).then(({ data }) => {
            const temp_coins = data.data;

            let coins_comma = [];

            temp_coins.map((ele) => {
              if (ele.attributes.coin_id) {
                coins_comma.push(ele.attributes.coin_id);
              }
            });

            axios(
              coins_comma.length > 0
                ? process.env.REACT_APP_PROXY_URL +
                    "/api/" +
                    coins_comma.join(",")
                : process.env.REACT_APP_PROXY_URL + "/api/5"
            ).then(({ data }) => {
              let coins = [];

              temp_coins.forEach((ele) => {
                const coin_api = data.data[ele.attributes.coin_id];

                if (coin_api) {
                  if (ele.attributes["d_7"] == null) {
                    ele.attributes["d_7"] =
                      coin_api["quote"]["USD"]["percent_change_7d"];
                  }
                  if (ele.attributes["h_24"] == null) {
                    ele.attributes["h_24"] =
                      coin_api["quote"]["USD"]["percent_change_24h"];
                  }
                  if (ele.attributes["market_cap"] == null) {
                    ele.attributes["market_cap"] =
                      coin_api["quote"]["USD"]["market_cap"];
                  }
                  if (ele.attributes["name"] == null) {
                    ele.attributes["name"] = coin_api["name"];
                  }
                  if (ele.attributes["price"] == null) {
                    ele.attributes["price"] = coin_api["quote"]["USD"]["price"];
                  }
                  if (ele.attributes["supply"] == null) {
                    ele.attributes["supply"] = coin_api["circulating_supply"];
                  }
                  if (ele.attributes["volume_24"] == null) {
                    ele.attributes["volume_24"] =
                      coin_api["quote"]["USD"]["volume_24h"];
                  }
                  coins.push(ele);
                } else {
                  coins.push(ele);
                }
              });

              console.log("endpoint", coins);

              axios(
                process.env.REACT_APP_SERVER_URL + "/api/columns-conversions"
              ).then((conversions_res) => {
                if (conversions_res.status == 200) {
                  if (conversions_res.data.data.length > 0) {
                    let columns_conversions = {};
                    let columns_icons = {};
                    let columns_infos = {};
                    conversions_res.data.data.forEach((ele) => {
                      columns_conversions[ele.attributes.label] =
                        ele.attributes.value;
                      columns_icons[ele.attributes.label] = ele.attributes.icon;
                      columns_infos[ele.attributes.label] = ele.attributes.info;
                    });
                    setisLoading(false);

                    setColumnsConversions(columns_conversions);
                    setColumnsIcons(columns_icons);
                    setColumnsInfos(columns_infos);

                    coins.sort(function (a, b) {
                      return a.id - b.id;
                    });
                    console.log("coins", coins, id);
                    setSimilarCoin(
                      shuffle(
                        coins.filter(
                          (e) =>
                            e.attributes.name.toLowerCase() !=
                            id.replaceAll("-", " ").toLowerCase()
                        )
                      )
                    );
                  }
                }
              });
            });
          });
        }
      }
    });

    axios(
      process.env.REACT_APP_SERVER_URL + `/api/blockchains?populate=*`
    ).then((res) => {
      if (res.status == 200) {
        if (res.data.data.length > 0) {
          let chains = {};

          res.data.data.forEach((ele) => {
            chains[ele.attributes.title] =
              ele.attributes.icon.data.attributes.url;
          });

          setBlockChainsData(chains);
        }
      }
    });

    axios(process.env.REACT_APP_SERVER_URL + `/api/platforms?populate=*`).then(
      (res) => {
        if (res.status == 200) {
          if (res.data.data.length > 0) {
            let data = {};

            res.data.data.forEach((ele) => {
              data[ele?.attributes?.title] =
                ele.attributes.icon.data[0].attributes.url;
            });

            setPlatformsData(data);
            setCryptoPlatforms(res.data.data);
          }
        }
      }
    );

    axios(
      process.env.REACT_APP_SERVER_URL +
        `/api/tables?filters[slug][$eq]=${table}&populate=columns`
    ).then((res) => {
      if (res.status == 200) {
        if (res.data.data.length == 0) {
          navigate("/404");
        }
        setColumns(res.data.data[0].attributes.columns.data);
        setis_trade(res.data.data[0].attributes.is_trade);
      }
    });

    axios(process.env.REACT_APP_SERVER_URL + "/api/columns-conversions").then(
      (conversions_res) => {
        if (conversions_res.status == 200) {
          if (conversions_res.data.data.length > 0) {
            let columns_conversions = {};
            let columns_icons = {};
            let columns_infos = {};

            conversions_res.data.data.forEach((ele) => {
              columns_conversions[ele.attributes.label] = ele.attributes.value;
              columns_icons[ele.attributes.label] = ele.attributes.icon;
              columns_infos[ele.attributes.label] = ele.attributes.info;
            });

            console.log("columns_infos", columns_infos);
            setColumnsConversions(columns_conversions);
            setColumnsIcons(columns_icons);
            setColumnsInfos(columns_infos);
          }
        }
      }
    );

    axios(
      `${process.env.REACT_APP_SERVER_URL}/api/blogs?sort=id:desc&pagination[limit]=3&populate=*`
    ).then((res) => {
      if (res.status == 200) {
        setRecentPosts(res.data.data);
      }
    });
  }, []);

  useEffect(() => {
    axios(
      `${process.env.REACT_APP_SERVER_URL}/api/favorites?populate=*&filters[$and][0][coin_id][$eq]=${CoinId}&filters[$and][1][user_id][$eq]=${User?.id}`
    ).then((res) => {
      if (res.status == 200) {
        if (res.data.data.length > 0) {
          setiswatch(true);
          setwatchId(res.data.data[0]["id"]);
        }
      }
    });
  }, [User, CoinId]);

  const RatingClassColor = (rating) => {
    if (rating >= 7) {
      return "rating green";
    } else if (rating >= 3) {
      return "rating yellow";
    } else if (rating >= 0) {
      return "rating red";
    }
  };

  function RemoveFavourite() {
    if (isLoggedin) {
      axios
        .delete(
          process.env.REACT_APP_SERVER_URL + "/api/favorites/" + watchId,
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("jwt"),
            },
          }
        )
        .then((res) => {
          if (res.status == 200) {
            setiswatch(false);
          }
        });
    }
  }

  function AddFavourite() {
    if (isLoggedin) {
      const body = {
        data: {
          user_id: User.id.toString(),

          coin_id: CoinId.toString(),
        },
      };
      axios
        .post(process.env.REACT_APP_SERVER_URL + "/api/favorites", body, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("jwt"),
          },
        })
        .then((res) => {
          if (res.status == 200) {
            setiswatch(true);
            setwatchId(res.data.data["id"]);
          }
        });
    }
  }

  return (
    <div className="container">
      <section
        className="page-title"
        style={{
          background: "transparent",
          padding: "20px",
        }}
      >
        <div className="">
          <div className="row">
            <div
              className="col-md-6 mobile_flex"
              style={{ display: "flex", gap: "15px" }}
            >
              <div>
                <img
                  className="coin_icon"
                  style={{
                    width: "120px",
                    height: "120px",
                    borderRadius: "50%",
                  }}
                  src={CoinIcon}
                  alt=""
                />
              </div>
              <div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    height: "100%",
                    justifyContent: "space-evenly",
                  }}
                >
                  <div
                    className="name_chain"
                    style={{
                      display: "flex",
                      gap: "15px",
                      alignItems: "center",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        gap: "5px",
                        alignItems: "center",
                      }}
                    >
                      <h3 className="heading" style={{ fontWeight: "500" }}>
                        {Coin?.name}
                      </h3>
                      <div>{Coin?.ticker}</div>
                    </div>
                    <div style={{ display: "flex", gap: "4px" }}>
                      {BlockChains.map((chain) => {
                        return (
                          <>
                            <img
                              style={{
                                width: "20px",
                                height: "20px",
                                borderRadius: "50%",
                              }}
                              src={
                                process.env.REACT_APP_SERVER_URL +
                                BlockChainsData[chain.attributes.title]
                              }
                              alt="rockie"
                            />
                          </>
                        );
                      })}
                    </div>
                  </div>

                  <div
                    style={{ display: "flex", gap: "10px", marginTop: "10px" }}
                  >
                    <div
                      onClick={() => {
                        !iswatch ? AddFavourite() : RemoveFavourite();
                      }}
                      target="_blank"
                      className="btn-action"
                      style={{
                        padding: "7px 24px",
                        background: "transparent",
                        border: !iswatch ? "1px solid" : "1px solid red",
                        color: theme == "light" ? "black" : "white",
                        cursor: "pointer",
                      }}
                    >
                      {!iswatch ? "Watchlist" : "UnWatch"}
                    </div>

                    {is_trade == true ? (
                      <a
                        href={TradeLink}
                        target="_blank"
                        className="btn-action"
                        style={{ padding: "7px 24px" }}
                      >
                        Trade
                      </a>
                    ) : (
                      <a
                        href={PlayLink}
                        target="_blank"
                        className="btn-action"
                        style={{ padding: "7px 24px" }}
                      >
                        Play
                      </a>
                    )}
                  </div>

                  <div className="coin_socials">
                    {DiscordSocial?.length > 0 && (
                      <a href={DiscordSocial} target="_blank">
                        <i
                          style={{ color: "#5865F2" }}
                          class="fa-brands fa-discord"
                        ></i>
                      </a>
                    )}

                    {TelegramSocial?.length > 0 && (
                      <a href={TelegramSocial} target="_blank">
                        <i
                          style={{ color: "#229ED9" }}
                          class="fa-brands fa-telegram"
                        ></i>
                      </a>
                    )}

                    {TwitterSocial?.length > 0 && (
                      <a href={TwitterSocial} target="_blank">
                        <i
                          style={{ color: "#1DA1F2" }}
                          class="fa-brands fa-twitter"
                        ></i>
                      </a>
                    )}

                    {FacebookSocial?.length > 0 && (
                      <a href={FacebookSocial} target="_blank">
                        <i
                          style={{ color: "#0866FF" }}
                          class="fa-brands fa-facebook"
                        ></i>
                      </a>
                    )}

                    {YoutubeSocial?.length > 0 && (
                      <a href={YoutubeSocial} target="_blank">
                        <i
                          style={{ color: "red" }}
                          class="fa-brands fa-youtube"
                        ></i>
                      </a>
                    )}

                    {MediumSocial?.length > 0 && (
                      <a href={MediumSocial} target="_blank">
                        <i class="fa-brands fa-medium"></i>
                      </a>
                    )}

                    {RedditSocial?.length > 0 && (
                      <a href={RedditSocial} target="_blank">
                        <i
                          class="fa-brands fa-reddit"
                          style={{ color: "orangered" }}
                        ></i>
                      </a>
                    )}

                    <a href={WebsiteSocial} target="_blank">
                      <i
                        style={{ color: "orange" }}
                        class="fa-solid fa-globe"
                      ></i>
                    </a>
                  </div>

                  <div style={{ marginTop: "10px" }}>Updated: {UpdatedAt} </div>
                </div>
              </div>
            </div>
            {ErrorMsg.length > 0 ? (
              <center className="col-md-6 " style={{ color: "red" }}>
                {ErrorMsg}
              </center>
            ) : (
              <div className="col-md-6 details_grid">
                {Columns.map((col, i) => {
                  if (
                    col?.attributes?.name != "Name" &&
                    col?.attributes?.name != "BlockChain"
                  ) {
                    if (Coin != undefined) {
                      if (col?.attributes?.name.includes("%")) {
                        if (
                          parseFloat(
                            Coin[ColumnsConversions[col?.attributes?.name]]
                          ) > 0
                        ) {
                          return (
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                gap: "10px",
                                background:
                                  theme == "light"
                                    ? "#f0f2f8"
                                    : "rgb(24, 25, 29)",
                              }}
                            >
                              <div className="icon_col">
                                <i
                                  className={
                                    ColumnsIcons[col?.attributes?.name]
                                  }
                                ></i>
                              </div>
                              <div>
                                <div style={{ fontSize: "12px" }}>
                                  {col?.attributes?.name.replace(" ", "")}{" "}
                                  <span className="info_col">
                                    <i
                                      data-tooltip-id={
                                        "tooltip-" + col?.attributes?.name
                                      }
                                      class="fa-solid fa-circle-info"
                                    ></i>
                                    <ReactTooltip
                                      id={"tooltip-" + col?.attributes?.name}
                                      place="bottom"
                                      content={
                                        ColumnsInfos[col?.attributes?.name]
                                      }
                                    />
                                  </span>
                                </div>
                                <div
                                  className="detail"
                                  style={{
                                    fontSize: "20px",
                                    color: "rgb(88, 189, 125)",
                                    fontWeight: "500",
                                    display: "flex",
                                  }}
                                >
                                  +
                                  {parseFloat(
                                    Coin[
                                      ColumnsConversions[col?.attributes?.name]
                                    ]
                                  ).toFixed(2)}{" "}
                                  <div>%</div>
                                </div>
                              </div>
                            </div>
                          );
                        } else {
                          return (
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                gap: "10px",
                                background:
                                  theme == "light"
                                    ? "#f0f2f8"
                                    : "rgb(24, 25, 29)",
                              }}
                            >
                              <div className="icon_col">
                                <i
                                  className={
                                    ColumnsIcons[col?.attributes?.name]
                                  }
                                ></i>
                              </div>
                              <div>
                                <div style={{ fontSize: "12px" }}>
                                  {col?.attributes?.name.replace(" ", "")}{" "}
                                  <span className="info_col">
                                    <i
                                      data-tooltip-id={
                                        "tooltip-" + col?.attributes?.name
                                      }
                                      class="fa-solid fa-circle-info"
                                    ></i>
                                    <ReactTooltip
                                      id={"tooltip-" + col?.attributes?.name}
                                      place="bottom"
                                      content={
                                        ColumnsInfos[col?.attributes?.name]
                                      }
                                    />
                                  </span>
                                </div>
                                <div
                                  className="detail"
                                  style={{
                                    fontSize: "20px",
                                    color: "red",
                                    fontWeight: "500",
                                    display: "flex",
                                  }}
                                >
                                  {parseFloat(
                                    Coin[
                                      ColumnsConversions[col?.attributes?.name]
                                    ]
                                  ).toFixed(2) == "NaN"
                                    ? "0"
                                    : parseFloat(
                                        Coin[
                                          ColumnsConversions[
                                            col?.attributes?.name
                                          ]
                                        ]
                                      ).toFixed(2)}{" "}
                                  <div>%</div>
                                </div>
                              </div>
                            </div>
                          );
                        }
                      } else if (
                        col?.attributes?.name == "Trust Rating" ||
                        col?.attributes?.name == "Earnings Rating"
                      ) {
                        let num = parseFloat(
                          Coin[ColumnsConversions[col?.attributes?.name]]
                        );
                        if (num >= 7) {
                          return (
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                gap: "10px",
                                background:
                                  theme == "light"
                                    ? "#f0f2f8"
                                    : "rgb(24, 25, 29)",
                              }}
                            >
                              <div className="icon_col">
                                <i
                                  className={
                                    ColumnsIcons[col?.attributes?.name]
                                  }
                                ></i>
                              </div>
                              <div>
                                <div style={{ fontSize: "12px" }}>
                                  {col?.attributes?.name}{" "}
                                  <span className="info_col">
                                    <i
                                      data-tooltip-id={
                                        "tooltip-" + col?.attributes?.name
                                      }
                                      class="fa-solid fa-circle-info"
                                    ></i>
                                    <ReactTooltip
                                      id={"tooltip-" + col?.attributes?.name}
                                      place="bottom"
                                      content={
                                        ColumnsInfos[col?.attributes?.name]
                                      }
                                    />
                                  </span>
                                </div>
                                <div
                                  className="detail rating green"
                                  style={{
                                    fontSize: "16px",
                                    color: "white",
                                    fontWeight: "500",
                                  }}
                                >
                                  {num}
                                </div>
                              </div>
                            </div>
                          );
                        } else if (num >= 3) {
                          return (
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                gap: "10px",
                                background:
                                  theme == "light"
                                    ? "#f0f2f8"
                                    : "rgb(24, 25, 29)",
                              }}
                            >
                              <div className="icon_col">
                                <i
                                  className={
                                    ColumnsIcons[col?.attributes?.name]
                                  }
                                ></i>
                              </div>

                              <div>
                                <div style={{ fontSize: "12px" }}>
                                  {col?.attributes?.name}{" "}
                                  <span className="info_col">
                                    <i
                                      data-tooltip-id={
                                        "tooltip-" + col?.attributes?.name
                                      }
                                      class="fa-solid fa-circle-info"
                                    ></i>
                                    <ReactTooltip
                                      id={"tooltip-" + col?.attributes?.name}
                                      place="bottom"
                                      content={
                                        ColumnsInfos[col?.attributes?.name]
                                      }
                                    />
                                  </span>
                                </div>
                                <div
                                  className="detail rating yellow"
                                  style={{
                                    fontSize: "16px",
                                    color: "white",
                                    fontWeight: "500",
                                  }}
                                >
                                  {num}
                                </div>
                              </div>
                            </div>
                          );
                        } else if (num >= 0) {
                          return (
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                gap: "10px",
                                background:
                                  theme == "light"
                                    ? "#f0f2f8"
                                    : "rgb(24, 25, 29)",
                              }}
                            >
                              <div className="icon_col">
                                <i
                                  className={
                                    ColumnsIcons[col?.attributes?.name]
                                  }
                                ></i>
                              </div>
                              <div>
                                <div style={{ fontSize: "12px" }}>
                                  {col?.attributes?.name}{" "}
                                  <span className="info_col">
                                    <i
                                      data-tooltip-id={
                                        "tooltip-" + col?.attributes?.name
                                      }
                                      class="fa-solid fa-circle-info"
                                    ></i>
                                    <ReactTooltip
                                      id={"tooltip-" + col?.attributes?.name}
                                      place="bottom"
                                      content={
                                        ColumnsInfos[col?.attributes?.name]
                                      }
                                    />
                                  </span>
                                </div>
                                <div
                                  className="detail rating red"
                                  style={{
                                    fontSize: "16px",
                                    color: "white",
                                    fontWeight: "500",
                                  }}
                                >
                                  {num}
                                </div>
                              </div>
                            </div>
                          );
                        }
                      } else if (col?.attributes?.name == "Platform") {
                        return (
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              gap: "10px",
                              background:
                                theme == "light"
                                  ? "#f0f2f8"
                                  : "rgb(24, 25, 29)",
                            }}
                          >
                            <div className="icon_col">
                              <i
                                className={ColumnsIcons[col?.attributes?.name]}
                              ></i>
                            </div>

                            <div>
                              <div style={{ fontSize: "12px" }}>
                                {col?.attributes?.name}{" "}
                                <span className="info_col">
                                  <i
                                    data-tooltip-id={
                                      "tooltip-" + col?.attributes?.name
                                    }
                                    class="fa-solid fa-circle-info"
                                  ></i>
                                  <ReactTooltip
                                    id={"tooltip-" + col?.attributes?.name}
                                    place="bottom"
                                    content={
                                      ColumnsInfos[col?.attributes?.name]
                                    }
                                  />
                                </span>
                              </div>
                              <div
                                style={{
                                  display: "flex",
                                  gap: "5px",
                                  flexWrap: "wrap",
                                }}
                              >
                                {Platforms.map((plat) => {
                                  return (
                                    <div>
                                      <img
                                        style={{
                                          width: "20px",
                                          height: "20px",
                                        }}
                                        src={
                                          process.env.REACT_APP_SERVER_URL +
                                          PlatformsData[plat.attributes.title]
                                        }
                                        alt="rockie"
                                      />
                                    </div>
                                  );
                                })}
                              </div>
                            </div>
                          </div>
                        );
                      } else if (col?.attributes?.name == "Genre") {
                        return (
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              gap: "10px",
                              background:
                                theme == "light"
                                  ? "#f0f2f8"
                                  : "rgb(24, 25, 29)",
                            }}
                          >
                            <div className="icon_col">
                              <i
                                className={ColumnsIcons[col?.attributes?.name]}
                              ></i>
                            </div>
                            <div>
                              <div style={{ fontSize: "12px" }}>
                                {col?.attributes?.name}{" "}
                                <span className="info_col">
                                  <i
                                    data-tooltip-id={
                                      "tooltip-" + col?.attributes?.name
                                    }
                                    class="fa-solid fa-circle-info"
                                  ></i>
                                  <ReactTooltip
                                    id={"tooltip-" + col?.attributes?.name}
                                    place="bottom"
                                    content={
                                      ColumnsInfos[col?.attributes?.name]
                                    }
                                  />
                                </span>
                              </div>
                              <div
                                style={{
                                  fontSize: "13px",
                                  color: theme == "light" ? "black" : "white",
                                  fontWeight: "500",
                                  display: "flex",
                                  gap: "3px",
                                  whiteSpace: "nowrap",
                                  flexWrap: "wrap",
                                }}
                              >
                                {Coin?.genres?.data?.map((gen, i) => {
                                  if (Coin.genres?.data.length > 1) {
                                    if (i != Coin?.genres?.data.length - 1) {
                                      return <div>{gen.attributes.title},</div>;
                                    } else {
                                      return <div>{gen.attributes.title}</div>;
                                    }
                                  } else {
                                    return <div>{gen.attributes.title}</div>;
                                  }
                                })}
                              </div>
                            </div>
                          </div>
                        );
                      } else if (col?.attributes?.name == "Status") {
                        return (
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              gap: "10px",
                              background:
                                theme == "light"
                                  ? "#f0f2f8"
                                  : "rgb(24, 25, 29)",
                            }}
                          >
                            <div className="icon_col">
                              <i
                                className={ColumnsIcons[col?.attributes?.name]}
                              ></i>
                            </div>

                            <div>
                              <div style={{ fontSize: "12px" }}>
                                {col?.attributes?.name}{" "}
                                <span className="info_col">
                                  <i
                                    data-tooltip-id={
                                      "tooltip-" + col?.attributes?.name
                                    }
                                    class="fa-solid fa-circle-info"
                                  ></i>
                                  <ReactTooltip
                                    id={"tooltip-" + col?.attributes?.name}
                                    place="bottom"
                                    content={
                                      ColumnsInfos[col?.attributes?.name]
                                    }
                                  />
                                </span>
                              </div>
                              <div
                                style={{
                                  fontSize: "13px",
                                  color: theme == "light" ? "black" : "white",
                                  fontWeight: "500",
                                  display: "flex",
                                  gap: "3px",
                                  whiteSpace: "nowrap",
                                  flexWrap: "wrap",
                                }}
                              >
                                {Coin?.statuses?.data?.map((gen, i) => {
                                  if (Coin.statuses?.data.length > 1) {
                                    if (i != Coin?.statuses?.data.length - 1) {
                                      return <div>{gen.attributes.title},</div>;
                                    } else {
                                      return <div>{gen.attributes.title}</div>;
                                    }
                                  } else {
                                    return <div>{gen.attributes.title}</div>;
                                  }
                                })}
                              </div>
                            </div>
                          </div>
                        );
                      } else if (
                        Coin[ColumnsConversions[col?.attributes?.name]] == true
                      ) {
                        return (
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              gap: "10px",
                              background:
                                theme == "light"
                                  ? "#f0f2f8"
                                  : "rgb(24, 25, 29)",
                            }}
                          >
                            <div className="icon_col">
                              <i
                                className={ColumnsIcons[col?.attributes?.name]}
                              ></i>
                            </div>

                            <div>
                              <div style={{ fontSize: "12px" }}>
                                {col?.attributes?.name}{" "}
                                <span className="info_col">
                                  <i
                                    data-tooltip-id={
                                      "tooltip-" + col?.attributes?.name
                                    }
                                    class="fa-solid fa-circle-info"
                                  ></i>
                                  <ReactTooltip
                                    id={"tooltip-" + col?.attributes?.name}
                                    place="bottom"
                                    content={
                                      ColumnsInfos[col?.attributes?.name]
                                    }
                                  />
                                </span>
                              </div>
                              <div
                                style={{
                                  fontSize: "20px",
                                  color: theme == "light" ? "black" : "white",
                                  fontWeight: "500",
                                }}
                              >
                                <i
                                  style={{ color: "rgb(88, 189, 125)" }}
                                  className="fa-solid fa-check"
                                ></i>
                              </div>
                            </div>
                          </div>
                        );
                      } else if (
                        Coin[ColumnsConversions[col?.attributes?.name]] == false
                      ) {
                        return (
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              gap: "10px",
                              background:
                                theme == "light"
                                  ? "#f0f2f8"
                                  : "rgb(24, 25, 29)",
                            }}
                          >
                            <div className="icon_col">
                              <i
                                className={ColumnsIcons[col?.attributes?.name]}
                              ></i>
                            </div>

                            <div>
                              <div style={{ fontSize: "12px" }}>
                                {col?.attributes?.name}{" "}
                                <span className="info_col">
                                  <i
                                    data-tooltip-id={
                                      "tooltip-" + col?.attributes?.name
                                    }
                                    class="fa-solid fa-circle-info"
                                  ></i>
                                  <ReactTooltip
                                    id={"tooltip-" + col?.attributes?.name}
                                    place="bottom"
                                    content={
                                      ColumnsInfos[col?.attributes?.name]
                                    }
                                  />
                                </span>
                              </div>
                              <div
                                style={{
                                  fontSize: "20px",
                                  color: theme == "light" ? "black" : "white",
                                  fontWeight: "500",
                                }}
                              >
                                <i
                                  style={{ color: "red" }}
                                  className="fa-solid fa-times"
                                ></i>
                              </div>
                            </div>
                          </div>
                        );
                      } else if (col?.attributes?.name == "Supply") {
                        return (
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              gap: "10px",
                              background:
                                theme == "light"
                                  ? "#f0f2f8"
                                  : "rgb(24, 25, 29)",
                            }}
                          >
                            <div className="icon_col">
                              <i
                                className={ColumnsIcons[col?.attributes?.name]}
                              ></i>
                            </div>
                            <div>
                              <div style={{ fontSize: "12px" }}>
                                {col?.attributes?.name}{" "}
                                <span className="info_col">
                                  <i
                                    data-tooltip-id={
                                      "tooltip-" + col?.attributes?.name
                                    }
                                    class="fa-solid fa-circle-info"
                                  ></i>
                                  <ReactTooltip
                                    id={"tooltip-" + col?.attributes?.name}
                                    place="bottom"
                                    content={
                                      ColumnsInfos[col?.attributes?.name]
                                    }
                                  />
                                </span>
                              </div>
                              {new Intl.NumberFormat("en-US", {
                                style: "currency",
                                currency: "USD",
                              })
                                .format(
                                  kFormatter(
                                    parseFloat(
                                      Coin[
                                        ColumnsConversions[
                                          col?.attributes?.name
                                        ]
                                      ]
                                    )
                                  )
                                    .toString()
                                    ?.split(".")[0]
                                )
                                ?.split(".")[0]
                                ?.replace("$", "") != "NaN" ? (
                                <div
                                  style={{
                                    fontSize: "20px",
                                    color: theme == "light" ? "black" : "white",
                                    fontWeight: "500",
                                  }}
                                >
                                  <span
                                    style={{
                                      fontSize: "14px",
                                      marginRight: "3px",
                                    }}
                                  >
                                    {Coin?.ticker}
                                  </span>
                                  {new Intl.NumberFormat("en-US", {
                                    style: "currency",
                                    currency: "USD",
                                  })
                                    .format(
                                      kFormatter(
                                        parseFloat(
                                          Coin[
                                            ColumnsConversions[
                                              col?.attributes?.name
                                            ]
                                          ]
                                        )
                                      )
                                        .toString()
                                        ?.split(".")[0]
                                    )
                                    ?.split(".")[0]
                                    ?.replace("$", "")}
                                  M
                                </div>
                              ) : (
                                <div
                                  style={{
                                    fontSize: "20px",
                                    color: theme == "light" ? "black" : "white",
                                    fontWeight: "500",
                                  }}
                                >
                                  -
                                </div>
                              )}
                            </div>
                          </div>
                        );
                      } else {
                        if (
                          parseFloat(
                            Coin[ColumnsConversions[col?.attributes?.name]]
                          ) > 1000000
                        ) {
                          return (
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                gap: "10px",
                                background:
                                  theme == "light"
                                    ? "#f0f2f8"
                                    : "rgb(24, 25, 29)",
                              }}
                            >
                              <div className="icon_col">
                                <i
                                  className={
                                    ColumnsIcons[col?.attributes?.name]
                                  }
                                ></i>
                              </div>
                              <div>
                                <div style={{ fontSize: "12px" }}>
                                  {col?.attributes?.name}{" "}
                                  <span className="info_col">
                                    <i
                                      data-tooltip-id={
                                        "tooltip-" + col?.attributes?.name
                                      }
                                      class="fa-solid fa-circle-info"
                                    ></i>
                                    <ReactTooltip
                                      id={"tooltip-" + col?.attributes?.name}
                                      place="bottom"
                                      content={
                                        ColumnsInfos[col?.attributes?.name]
                                      }
                                    />
                                  </span>
                                </div>
                                <div
                                  style={{
                                    fontSize: "20px",
                                    color: theme == "light" ? "black" : "white",
                                    fontWeight: "500",
                                  }}
                                >
                                  {
                                    new Intl.NumberFormat("en-US", {
                                      style: "currency",
                                      currency: "USD",
                                    })
                                      .format(
                                        kFormatter(
                                          parseFloat(
                                            Coin[
                                              ColumnsConversions[
                                                col?.attributes?.name
                                              ]
                                            ]
                                          )
                                        )?.split(".")[0]
                                      )
                                      .split(".")[0]
                                  }
                                  M
                                </div>
                              </div>
                            </div>
                          );
                        } else {
                          if (
                            parseFloat(
                              Coin[ColumnsConversions[col?.attributes?.name]]
                            ) > 0.01
                          ) {
                            return (
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  gap: "10px",
                                  background:
                                    theme == "light"
                                      ? "#f0f2f8"
                                      : "rgb(24, 25, 29)",
                                }}
                              >
                                <div className="icon_col">
                                  <i
                                    className={
                                      ColumnsIcons[col?.attributes?.name]
                                    }
                                  ></i>
                                </div>
                                <div>
                                  <div style={{ fontSize: "12px" }}>
                                    {col?.attributes?.name}{" "}
                                    <span className="info_col">
                                      <i
                                        data-tooltip-id={
                                          "tooltip-" + col?.attributes?.name
                                        }
                                        class="fa-solid fa-circle-info"
                                      ></i>
                                      <ReactTooltip
                                        id={"tooltip-" + col?.attributes?.name}
                                        place="bottom"
                                        content={
                                          ColumnsInfos[col?.attributes?.name]
                                        }
                                      />
                                    </span>
                                  </div>
                                  <div
                                    style={{
                                      fontSize: "20px",
                                      color:
                                        theme == "light" ? "black" : "white",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {new Intl.NumberFormat("en-US", {
                                      style: "currency",
                                      currency: "USD",
                                    }).format(
                                      parseFloat(
                                        Coin[
                                          ColumnsConversions[
                                            col?.attributes?.name
                                          ]
                                        ]
                                      )
                                    )}
                                  </div>
                                </div>
                              </div>
                            );
                          } else {
                            if (
                              Coin[ColumnsConversions[col?.attributes?.name]]
                            ) {
                              return (
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    gap: "10px",
                                    background:
                                      theme == "light"
                                        ? "#f0f2f8"
                                        : "rgb(24, 25, 29)",
                                  }}
                                >
                                  <div className="icon_col">
                                    <i
                                      className={
                                        ColumnsIcons[col?.attributes?.name]
                                      }
                                    ></i>
                                  </div>
                                  <div>
                                    <div style={{ fontSize: "12px" }}>
                                      {col?.attributes?.name}{" "}
                                      <span className="info_col">
                                        <i
                                          data-tooltip-id={
                                            "tooltip-" + col?.attributes?.name
                                          }
                                          class="fa-solid fa-circle-info"
                                        ></i>
                                        <ReactTooltip
                                          id={
                                            "tooltip-" + col?.attributes?.name
                                          }
                                          place="bottom"
                                          content={
                                            ColumnsInfos[col?.attributes?.name]
                                          }
                                        />
                                      </span>
                                    </div>
                                    <div
                                      style={{
                                        fontSize: "20px",
                                        color:
                                          theme == "light" ? "black" : "white",
                                        fontWeight: "500",
                                      }}
                                    >
                                      $
                                      {parseFloat(
                                        Coin[
                                          ColumnsConversions[
                                            col?.attributes?.name
                                          ]
                                        ]
                                      ).toFixed(4)}
                                    </div>
                                  </div>
                                </div>
                              );
                            } else {
                              return (
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    gap: "10px",
                                    background:
                                      theme == "light"
                                        ? "#f0f2f8"
                                        : "rgb(24, 25, 29)",
                                  }}
                                >
                                  <div className="icon_col">
                                    <i
                                      className={
                                        ColumnsIcons[col?.attributes?.name]
                                      }
                                    ></i>
                                  </div>
                                  <div>
                                    <div style={{ fontSize: "12px" }}>
                                      {col?.attributes?.name}{" "}
                                      <span className="info_col">
                                        <i
                                          data-tooltip-id={
                                            "tooltip-" + col?.attributes?.name
                                          }
                                          class="fa-solid fa-circle-info"
                                        ></i>
                                        <ReactTooltip
                                          id={
                                            "tooltip-" + col?.attributes?.name
                                          }
                                          place="bottom"
                                          content={
                                            ColumnsInfos[col?.attributes?.name]
                                          }
                                        />
                                      </span>
                                    </div>
                                    <div
                                      style={{
                                        fontSize: "20px",
                                        color:
                                          theme == "light" ? "black" : "white",
                                        fontWeight: "500",
                                      }}
                                    >
                                      -
                                    </div>
                                  </div>
                                </div>
                              );
                            }
                          }
                        }
                      }
                    }
                  }
                })}

                <div>
                  <div></div>
                </div>
              </div>
            )}
          </div>
        </div>
      </section>

      <div style={{ padding: "30px" }}>
        {Carousel?.length > 0 && (
          <Swiper
            slidesPerView={CarouselPerPage}
            spaceBetween={20}
            pagination={{
              clickable: true,
            }}
            loop={true}
            className="mySwiper"
          >
            {Carousel.map((ele) => {
              return (
                <SwiperSlide>
                  <img
                    style={{
                      width: "100%",
                      height: CarouselPerPage == 1.5 ? "150px" : "250px",
                      borderRadius: "15px",
                    }}
                    src={process.env.REACT_APP_SERVER_URL + ele.attributes.url}
                    alt=""
                  />
                </SwiperSlide>
              );
            })}
          </Swiper>
        )}
        {CarouselLinks?.length > 0 && (
          <Swiper
            slidesPerView={CarouselPerPage}
            spaceBetween={20}
            pagination={{
              clickable: true,
            }}
            loop={true}
            className="mySwiper"
          >
            {CarouselLinks.map((ele) => {
              return (
                <SwiperSlide>
                  <img
                    style={{
                      width: "100%",
                      height: CarouselPerPage == 1.5 ? "150px" : "250px",
                      borderRadius: "15px",
                    }}
                    src={ele}
                    alt=""
                  />
                </SwiperSlide>
              );
            })}
          </Swiper>
        )}
      </div>

      <div
        className="content blog_content"
        style={{ padding: "30px" }}
        data-color-mode={theme}
      >
        <h1 style={{ fontSize: "33px" }}>{heading_title}</h1>
        <br />
        <br />
        <MarkdownPreview source={Description} />
      </div>

      <section
        className="crypto"
        data-aos="fade-up"
        data-aos-duration="1000"
        style={{ marginTop: "20px" }}
      >
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="" style={{ boxShadow: "unset" }}>
                {is_tokenomics && (
                  <div
                    className="stats_crypto"
                    style={{
                      display: "flex",
                      gap: "10px",
                      background: "transparent",
                    }}
                  >
                    <div
                      className={`crypto-box active`}
                      style={{ width: "100%" }}
                    >
                      <div
                        style={{
                          display: "flex",
                          gap: "5px",
                          fontSize: "20px",
                        }}
                      >
                        <div style={{ color: "#3772ff" }}>
                          <i class="fa-solid fa-coins"></i>
                        </div>
                        <div
                          style={{
                            color: theme == "light" ? "black" : "white",
                            fontWeight: "500",
                          }}
                        >
                          Tokenomics
                        </div>
                      </div>

                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "space-evenly",
                          height: "100%",
                        }}
                      >
                        <div className="tokens_info">
                          <div>Circulating Supply</div>
                          <div
                            style={{
                              color: theme == "light" ? "black" : "white",
                            }}
                          >
                            {new Intl.NumberFormat("en-US", {
                              style: "currency",
                              currency: "USD",
                            })
                              .format(parseFloat(Coin?.supply))
                              .toString()
                              .replace("$", "")
                              .split(".")[0] == "NaN"
                              ? "-"
                              : new Intl.NumberFormat("en-US", {
                                  style: "currency",
                                  currency: "USD",
                                })
                                  .format(parseFloat(Coin?.supply))
                                  .toString()
                                  .replace("$", "")
                                  .split(".")[0]}
                          </div>
                        </div>
                        <div className="tokens_info">
                          <div>Total Supply</div>
                          <div
                            style={{
                              color: theme == "light" ? "black" : "white",
                            }}
                          >
                            {new Intl.NumberFormat("en-US", {
                              style: "currency",
                              currency: "USD",
                            })
                              .format(parseFloat(Coin?.total_supply))
                              .toString()
                              .replace("$", "")
                              .split(".")[0] == "NaN"
                              ? "-"
                              : new Intl.NumberFormat("en-US", {
                                  style: "currency",
                                  currency: "USD",
                                })
                                  .format(parseFloat(Coin?.total_supply))
                                  .toString()
                                  .replace("$", "")
                                  .split(".")[0]}
                          </div>
                        </div>
                        <div className="tokens_info">
                          <div>Max Supply</div>
                          <div
                            style={{
                              color: theme == "light" ? "black" : "white",
                            }}
                          >
                            {" "}
                            {new Intl.NumberFormat("en-US", {
                              style: "currency",
                              currency: "USD",
                            })
                              .format(parseFloat(Coin?.max_supply))
                              .toString()
                              .replace("$", "")
                              .split(".")[0] == "NaN"
                              ? "-"
                              : new Intl.NumberFormat("en-US", {
                                  style: "currency",
                                  currency: "USD",
                                })
                                  .format(parseFloat(Coin?.max_supply))
                                  .toString()
                                  .replace("$", "")
                                  .split(".")[0]}
                          </div>
                        </div>
                        <div className="tokens_info">
                          <div>Fully Diluted Market Cap</div>
                          <div
                            style={{
                              color: theme == "light" ? "black" : "white",
                            }}
                          >
                            {" "}
                            {new Intl.NumberFormat("en-US", {
                              style: "currency",
                              currency: "USD",
                            })
                              .format(
                                parseFloat(Coin?.fully_diluted_market_cap)
                              )
                              .toString()
                              .replace("$", "")
                              .split(".")[0] == "NaN"
                              ? "-"
                              : new Intl.NumberFormat("en-US", {
                                  style: "currency",
                                  currency: "USD",
                                })
                                  .format(
                                    parseFloat(Coin?.fully_diluted_market_cap)
                                  )
                                  .toString()
                                  .replace("$", "")
                                  .split(".")[0]}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div
                      onScroll={(e) => {
                        console.log(
                          e.target.scrollTop > 20
                            ? setshowscroll(false)
                            : setshowscroll(true)
                        );
                      }}
                      id="contracts_cont"
                      className={`crypto-box active`}
                      style={{
                        width: "100%",
                        maxHeight: "275px",
                        overflowY: "auto",
                        position: "relative",
                      }}
                    >
                      {showscroll && (
                        <div
                          style={{
                            position: "absolute",
                            bottom: "10px",
                            width: "100%",
                            display: "flex",
                            justifyContent: "center",

                            left: "0",
                          }}
                        >
                          <div
                            onClick={() => {
                              document
                                .getElementById("contracts_cont")
                                .scrollTo({
                                  behavior: "smooth",
                                  top: document.getElementById("contracts_cont")
                                    .scrollHeight,
                                });
                            }}
                            className="contract_scroll"
                            style={{
                              width: "40px",
                              height: "40px",
                              background: "white",
                              borderRadius: "50%",
                              boxShadow:
                                "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              color: "black",
                            }}
                          >
                            <i class="fa-solid fa-arrow-down"></i>
                          </div>
                        </div>
                      )}

                      <div
                        style={{
                          display: "flex",
                          gap: "5px",
                          fontSize: "20px",
                        }}
                      >
                        <div style={{ color: "#3772ff" }}>
                          <i class="fa-solid fa-file-contract"></i>
                        </div>
                        <div
                          style={{
                            color: theme == "light" ? "black" : "white",
                            fontWeight: "500",
                          }}
                        >
                          Contracts
                        </div>
                      </div>

                      <div>
                        {Coin?.bnb_contract && (
                          <Contract
                            img={BnB}
                            name={"BNB Chain"}
                            contract={Coin?.bnb_contract}
                          />
                        )}
                        {Coin?.ethereum_contract && (
                          <Contract
                            img={Ethereum}
                            name={"Ethereum"}
                            contract={Coin?.ethereum_contract}
                          />
                        )}
                        {Coin?.harmony_contract && (
                          <>
                            <Contract
                              img={Harmony}
                              name={"Harmony"}
                              contract={Coin?.harmony_contract}
                            />
                          </>
                        )}
                        {Coin?.energi_contract && (
                          <Contract
                            img={Energi}
                            name={"Energi"}
                            contract={Coin?.energi_contract}
                          />
                        )}
                        {Coin?.solana_contract && (
                          <Contract
                            img={
                              process.env.REACT_APP_SERVER_URL +
                              "/uploads/solana_20220613095447_d3095573b6.png"
                            }
                            name={"Solana"}
                            contract={Coin?.solana_contract}
                          />
                        )}
                        {Coin?.polygon_contract && (
                          <Contract
                            img={
                              process.env.REACT_APP_SERVER_URL +
                              "/uploads/3890_5ec94a4720.png"
                            }
                            name={"Polygon"}
                            contract={Coin?.polygon_contract}
                          />
                        )}
                        {Coin?.binance_contract && (
                          <Contract
                            img={
                              process.env.REACT_APP_SERVER_URL +
                              "/uploads/1839_fefd3f9b12.png"
                            }
                            name={"Binance Smart Chain"}
                            contract={Coin?.binance_contract}
                          />
                        )}
                        {Coin?.cardano_contract && (
                          <Contract
                            img={
                              process.env.REACT_APP_SERVER_URL +
                              "/uploads/2010_68c29c4a2c.png"
                            }
                            name={"Cardano"}
                            contract={Coin?.cardano_contract}
                          />
                        )}
                        {Coin?.avalanche_contract && (
                          <Contract
                            img={
                              process.env.REACT_APP_SERVER_URL +
                              "/uploads/5805_c0dab43671.png"
                            }
                            name={"Avalanche"}
                            contract={Coin?.avalanche_contract}
                          />
                        )}
                        {Coin?.immutable_contract && (
                          <Contract
                            img={
                              process.env.REACT_APP_SERVER_URL +
                              "/uploads/10603_3857cc3515.png"
                            }
                            name={"Immutable"}
                            contract={Coin?.immutable_contract}
                          />
                        )}
                        {Coin?.cosmos_contract && (
                          <Contract
                            img={
                              process.env.REACT_APP_SERVER_URL +
                              "/uploads/3794_5e9c978a8f.png"
                            }
                            name={"Cosmos"}
                            contract={Coin?.cosmos_contract}
                          />
                        )}
                        {Coin?.optimism_contract && (
                          <Contract
                            img={
                              process.env.REACT_APP_SERVER_URL +
                              "/uploads/11840_d89b712403.png"
                            }
                            name={"Optimism"}
                            contract={Coin?.optimism_contract}
                          />
                        )}
                        {Coin?.near_contract && (
                          <Contract
                            img={
                              process.env.REACT_APP_SERVER_URL +
                              "/uploads/6535_2802e4e63b.png"
                            }
                            name={"Near"}
                            contract={Coin?.near_contract}
                          />
                        )}
                        {Coin?.aptos_contract && (
                          <Contract
                            img={
                              process.env.REACT_APP_SERVER_URL +
                              "/uploads/21794_554ab8056f.png"
                            }
                            name={"Aptos"}
                            contract={Coin?.aptos_contract}
                          />
                        )}
                        {Coin?.sui_contract && (
                          <Contract
                            img={
                              process.env.REACT_APP_SERVER_URL +
                              "/uploads/20947_a6eb09faf6.png"
                            }
                            name={"Sui"}
                            contract={Coin?.sui_contract}
                          />
                        )}
                        {Coin?.arbitrum_contract && (
                          <Contract
                            img={
                              process.env.REACT_APP_SERVER_URL +
                              "/uploads/11841_60d802012c.png"
                            }
                            name={"Arbitrum"}
                            contract={Coin?.arbitrum_contract}
                          />
                        )}
                        {Coin?.fantom_contract && (
                          <Contract
                            img={
                              process.env.REACT_APP_SERVER_URL +
                              "/uploads/3513_2b24428adb.png"
                            }
                            name={"Fantom"}
                            contract={Coin?.fantom_contract}
                          />
                        )}
                        {Coin?.ronin_contract && (
                          <Contract
                            img={
                              process.env.REACT_APP_SERVER_URL +
                              "/uploads/14101_0083196a63.png"
                            }
                            name={"Ronin"}
                            contract={Coin?.ronin_contract}
                          />
                        )}
                        {Coin?.base_contract && (
                          <Contract
                            img={
                              process.env.REACT_APP_SERVER_URL +
                              "/uploads/27716_6be879a2d6.png"
                            }
                            name={"Base"}
                            contract={Coin?.base_contract}
                          />
                        )}
                        {Coin?.other_contract && (
                          <Contract
                            img={
                              process.env.REACT_APP_SERVER_URL +
                              "/uploads/other_55c93bb76b.png"
                            }
                            name={"Other"}
                            contract={Coin?.other_contract}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>

      <BlogCoin BlogData={RecentPosts} />
      <div className="col-md-12" style={{ padding: "15px" }}>
        <div className="block-text ">
          <h3 className="heading">Similar Coins</h3>
        </div>
      </div>
      <Crypto03
        Coins={SimilarCoin}
        Platforms={PlatformsData}
        RatingClassColor={RatingClassColor}
        isLoading={isLoading}
        BlockChains={BlockChainsData}
        Columns={Columns}
        ismargin={false}
        TableSlug={table}
        theme={theme}
      />
      <br />
      <br />
      <br />
    </div>
  );
}

export default CoinDetails;
