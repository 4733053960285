import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { LoginContext } from "./LoginContext";
import axios from "axios";
import { Tooltip as ReactTooltip } from "react-tooltip";

function CoinsTable({
  Coins,
  TableSlug,
  Columns,
  ColumnsConversions,
  is_trade,
  BlockChains,
  Platforms,
  itemstart = 0,
  itemend = 20,
  Adverts = [],
}) {
  const navigate = useNavigate();
  const { isLoggedin, User, theme } = useContext(LoginContext);

  const [Favs, setFavs] = useState([]);

  function RemoveFavourite(watchId) {
    if (isLoggedin) {
      axios
        .delete(
          process.env.REACT_APP_SERVER_URL + "/api/favorites/" + watchId,
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("jwt"),
            },
          }
        )
        .then((res) => {
          if (res.status == 200) {
            fetchFavs();
          }
        });
    }
  }

  function AddFavourite(CoinId) {
    if (isLoggedin) {
      const body = {
        data: {
          user_id: User.id.toString(),

          coin_id: CoinId.toString(),
        },
      };
      axios
        .post(process.env.REACT_APP_SERVER_URL + "/api/favorites", body, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("jwt"),
          },
        })
        .then((res) => {
          if (res.status == 200) {
            fetchFavs();
          }
        });
    }
  }

  useEffect(() => {
    fetchFavs();
  }, [User]);

  function fetchFavs() {
    if (isLoggedin) {
      axios(
        process.env.REACT_APP_SERVER_URL +
          "/api/favorites?populate=*&filters[user_id][$eq]=" +
          User?.id
      ).then((res) => {
        if (res.status == 200) {
          setFavs(res.data.data);
        }
      });
    }
  }

  return (
    <table className="table">
      <thead>
        <tr>
          <th scope="col"></th>
          <th scope="col">#</th>
          {Columns.map((ele, i) => {
            return (
              <th key={i} scope="col" style={{ whiteSpace: "nowrap" }}>
                {ele.attributes.name}
              </th>
            );
          })}

          <th scope="col"></th>
        </tr>
      </thead>
      <tbody className="coins_tbody">
        {Adverts.length > 0 && (
          <>
            {Adverts.map((ele, i) => {
              return (
                <tr
                  key={i}
                  onClick={(e) => {
                    if (!e.target.className.includes("star")) {
                      navigate("/games/" + ele.attributes.slug);
                    }
                  }}
                >
                  <td>
                    <ReactTooltip
                      id={"tooltip-" + ele.id.toString()}
                      place="bottom"
                      content={
                        "Please login to start adding games to your favorites list!"
                      }
                    />
                    <span
                      data-tooltip-id={
                        !isLoggedin ? "tooltip-" + ele.id.toString() : ""
                      }
                      style={{
                        color:
                          Favs.filter(
                            (coin) =>
                              coin.attributes.coin_id == ele.id.toString()
                          ).length == 0
                            ? theme == "light"
                              ? "black"
                              : "white"
                            : "#3772ff",
                      }}
                      className={
                        Favs.filter(
                          (coin) => coin.attributes.coin_id == ele.id.toString()
                        ).length == 0
                          ? "fa-regular fa-star coin_star"
                          : "fa-solid fa-star coin_star"
                      }
                      onClick={(e) => {
                        if (isLoggedin) {
                          if (
                            e.target.className == "fa-solid fa-star coin_star"
                          ) {
                            console.log(
                              Favs.filter(
                                (coin) =>
                                  coin.attributes.coin_id == ele.id.toString()
                              )
                            );
                            RemoveFavourite(
                              Favs.filter(
                                (coin) =>
                                  coin.attributes.coin_id == ele.id.toString()
                              )[0]["id"]
                            );
                            e.target.className = "fa-regular fa-star coin_star";
                            e.target.style.color =
                              theme == "light" ? "black" : "white";
                          } else {
                            console.log("yes");
                            AddFavourite(ele.id.toString());
                            e.target.className = "fa-solid fa-star coin_star";
                            e.target.style.color = "rgb(55, 114, 255)";
                          }
                        }
                      }}
                    ></span>
                  </td>
                  <td>Ad</td>
                  {Columns.map((col, i) => {
                    if (col.attributes.name == "Name") {
                      return (
                        <td>
                          <div style={{ display: "flex", gap: "10px" }} to="#">
                            <img
                              style={{
                                width: "30px",
                                height: "30px",
                                borderRadius: "50%",
                              }}
                              src={
                                ele.attributes?.icon_url?.length > 0
                                  ? ele.attributes?.icon_url
                                  : process.env.REACT_APP_SERVER_URL +
                                    ele.attributes?.icon?.data?.attributes?.url
                              }
                              alt="rockie"
                            />
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                              }}
                            >
                              <span
                                style={{
                                  fontWeight: "bold",
                                  fontSize: "16px",
                                  whiteSpace: "nowrap",
                                }}
                              >
                                {ele.attributes.name}
                              </span>
                              <span
                                style={{
                                  fontSize: "12px",
                                  color: "gray",
                                  fontWeight: "500",
                                }}
                              >
                                {ele.attributes.ticker}
                              </span>
                            </div>
                          </div>
                        </td>
                      );
                    } else if (col.attributes.name == "BlockChain") {
                      return (
                        <td>
                          <div
                            style={{
                              display: "flex",
                              flexWrap: "wrap",
                              gap: "3px",
                            }}
                          >
                            {ele.attributes.blockchains?.data?.map((chain) => {
                              return (
                                <>
                                  <img
                                    style={{
                                      width: "20px",
                                      height: "20px",
                                      borderRadius: "50%",
                                    }}
                                    src={
                                      process.env.REACT_APP_SERVER_URL +
                                      BlockChains[chain.attributes.title]
                                    }
                                    alt="rockie"
                                  />
                                </>
                              );
                            })}
                          </div>
                        </td>
                      );
                    } else if (col.attributes.name == "Platform") {
                      return (
                        <td>
                          <div
                            style={{
                              display: "flex",
                              flexWrap: "wrap",
                              gap: "3px",
                            }}
                          >
                            {ele.attributes.platforms?.data.map((chain) => {
                              return (
                                <>
                                  <img
                                    style={{
                                      width: "20px",
                                      height: "20px",
                                    }}
                                    src={
                                      process.env.REACT_APP_SERVER_URL +
                                      Platforms[chain.attributes.title]
                                    }
                                    alt="blockchains"
                                  />
                                </>
                              );
                            })}
                          </div>
                        </td>
                      );
                    } else if (col.attributes.name == "Genre") {
                      return (
                        <td style={{ fontSize: "13px" }}>
                          {ele.attributes?.genres?.data?.map((gen, i) => {
                            if (ele.attributes?.genres?.data.length > 1) {
                              if (
                                i !=
                                ele.attributes?.genres?.data.length - 1
                              ) {
                                return <div>{gen.attributes.title},</div>;
                              } else {
                                return <div>{gen.attributes.title}</div>;
                              }
                            } else {
                              return <div>{gen.attributes.title}</div>;
                            }
                          })}
                        </td>
                      );
                    } else if (col.attributes.name == "Status") {
                      return (
                        <td style={{ fontSize: "13px" }}>
                          {ele.attributes?.statuses?.data?.map((gen, i) => {
                            if (ele.attributes?.statuses?.data.length > 1) {
                              if (
                                i !=
                                ele.attributes?.statuses?.data.length - 1
                              ) {
                                return <div>{gen.attributes.title},</div>;
                              } else {
                                return <div>{gen.attributes.title}</div>;
                              }
                            } else {
                              return <div>{gen.attributes.title}</div>;
                            }
                          })}
                        </td>
                      );
                    } else {
                      if (
                        !isNaN(
                          ele.attributes[
                            ColumnsConversions[col.attributes.name]
                          ]
                        )
                      ) {
                        if (col.attributes.name.includes("%")) {
                          if (
                            parseFloat(
                              ele.attributes[
                                ColumnsConversions[col.attributes.name]
                              ]
                            ).toFixed(2) != "NaN"
                          ) {
                            if (
                              ele.attributes[
                                ColumnsConversions[col.attributes.name]
                              ]?.toString()[0] == "-"
                            ) {
                              return (
                                <td
                                  key={i}
                                  style={{
                                    fontWeight: "300",
                                    color: "#d33535",
                                  }}
                                >
                                  {parseFloat(
                                    ele.attributes[
                                      ColumnsConversions[col.attributes.name]
                                    ]
                                  ).toFixed(2) == "NaN"
                                    ? "-"
                                    : parseFloat(
                                        ele.attributes[
                                          ColumnsConversions[
                                            col.attributes.name
                                          ]
                                        ]
                                      ).toFixed(2)}
                                  %
                                </td>
                              );
                            } else {
                              return (
                                <td
                                  key={i}
                                  style={{
                                    fontWeight: "300",
                                    color: "#58bd7d",
                                  }}
                                >
                                  +
                                  {parseFloat(
                                    ele.attributes[
                                      ColumnsConversions[col.attributes.name]
                                    ]
                                  ).toFixed(2) == "NaN"
                                    ? "-"
                                    : parseFloat(
                                        ele.attributes[
                                          ColumnsConversions[
                                            col.attributes.name
                                          ]
                                        ]
                                      ).toFixed(2)}
                                  %
                                </td>
                              );
                            }
                          } else {
                            return (
                              <td
                                key={i}
                                style={{
                                  fontWeight: "300",
                                  color: "#58bd7d",
                                }}
                              >
                                -
                              </td>
                            );
                          }
                        } else if (
                          col.attributes.name == "Trust Rating" ||
                          col.attributes.name == "Earnings Rating"
                        ) {
                          let num = parseFloat(
                            ele.attributes[
                              ColumnsConversions[col.attributes.name]
                            ]
                          );
                          if (num >= 7) {
                            return (
                              <td key={i}>
                                <div className="rating green">{num}</div>
                              </td>
                            );
                          } else if (num >= 3) {
                            return (
                              <td key={i}>
                                <div className="rating yellow">{num}</div>
                              </td>
                            );
                          } else if (num >= 0) {
                            return (
                              <td key={i}>
                                <div className="rating red">{num}</div>
                              </td>
                            );
                          }
                        } else {
                          if (
                            ele.attributes[
                              ColumnsConversions[col.attributes.name]
                            ] == null ||
                            ele.attributes[
                              ColumnsConversions[col.attributes.name]
                            ] == 0
                          ) {
                            return <td>-</td>;
                          } else {
                            if (
                              ele.attributes[
                                ColumnsConversions[col.attributes.name]
                              ] == true ||
                              ele.attributes[
                                ColumnsConversions[col.attributes.name]
                              ] == false
                            ) {
                              if (
                                ele.attributes[
                                  ColumnsConversions[col.attributes.name]
                                ] == true
                              ) {
                                return (
                                  <td>
                                    <i
                                      style={{ color: "#58bd7d" }}
                                      className="fa-solid fa-check"
                                    ></i>
                                  </td>
                                );
                              } else {
                                return (
                                  <td>
                                    <i
                                      style={{ color: "#d33535" }}
                                      className="fa-solid fa-times"
                                    ></i>
                                  </td>
                                );
                              }
                            } else {
                              if (
                                parseFloat(
                                  ele.attributes[
                                    ColumnsConversions[col.attributes.name]
                                  ]
                                ) >= 1000000
                              ) {
                                return (
                                  <td key={i}>
                                    <div>
                                      {new Intl.NumberFormat("en-US", {
                                        style: "currency",
                                        currency: "USD",
                                      })
                                        .format(
                                          parseFloat(
                                            ele.attributes[
                                              ColumnsConversions[
                                                col.attributes.name
                                              ]
                                            ]
                                          )
                                        )
                                        .toString()
                                        .split(".")[0] == "NaN"
                                        ? "-"
                                        : new Intl.NumberFormat("en-US", {
                                            style: "currency",
                                            currency: "USD",
                                          })
                                            .format(
                                              parseFloat(
                                                ele.attributes[
                                                  ColumnsConversions[
                                                    col.attributes.name
                                                  ]
                                                ]
                                              )
                                            )
                                            .toString()
                                            .split(".")[0]}
                                    </div>
                                  </td>
                                );
                              } else {
                                if (
                                  parseFloat(
                                    ele.attributes[
                                      ColumnsConversions[col.attributes.name]
                                    ]
                                  ) > 0.01
                                ) {
                                  return (
                                    <td key={i}>
                                      {new Intl.NumberFormat("en-US", {
                                        style: "currency",
                                        currency: "USD",
                                      }).format(
                                        parseFloat(
                                          ele.attributes[
                                            ColumnsConversions[
                                              col.attributes.name
                                            ]
                                          ]
                                        )
                                      )}
                                    </td>
                                  );
                                } else {
                                  if (
                                    ele.attributes[
                                      ColumnsConversions[col.attributes.name]
                                    ]
                                  ) {
                                    return (
                                      <td key={i}>
                                        $
                                        {parseFloat(
                                          ele.attributes[
                                            ColumnsConversions[
                                              col.attributes.name
                                            ]
                                          ]
                                        ).toFixed(4)}
                                      </td>
                                    );
                                  } else {
                                    return <div></div>;
                                  }
                                }
                              }
                            }
                          }
                        }
                      } else {
                        return (
                          <td key={i}>
                            {
                              ele.attributes[
                                ColumnsConversions[col.attributes.name]
                              ]
                            }
                          </td>
                        );
                      }
                    }
                  })}

                  {is_trade == true ? (
                    <td>
                      <a
                        target="_blank"
                        href={ele.attributes.trade_link}
                        className="btn"
                      >
                        Trade
                      </a>
                    </td>
                  ) : (
                    <td>
                      <a
                        target="_blank"
                        href={ele.attributes.play_link}
                        className="btn"
                      >
                        Play
                      </a>
                    </td>
                  )}
                </tr>
              );
            })}
          </>
        )}
        {Coins.slice(itemstart, itemend).map((ele, i) => {
          return (
            <tr
              key={i}
              onClick={(e) => {
                if (!e.target.className.includes("star")) {
                  navigate("/games/" + ele.attributes.slug);
                }
              }}
            >
              <td>
                <ReactTooltip
                  id={"tooltip-" + ele.id.toString()}
                  place="bottom"
                  content={
                    "Please login to start adding games to your favorites list!"
                  }
                />
                <span
                  data-tooltip-id={
                    !isLoggedin ? "tooltip-" + ele.id.toString() : ""
                  }
                  style={{
                    color:
                      Favs.filter(
                        (coin) => coin.attributes.coin_id == ele.id.toString()
                      ).length == 0
                        ? theme == "light"
                          ? "black"
                          : "white"
                        : "#3772ff",
                  }}
                  className={
                    Favs.filter(
                      (coin) => coin.attributes.coin_id == ele.id.toString()
                    ).length == 0
                      ? "fa-regular fa-star coin_star"
                      : "fa-solid fa-star coin_star"
                  }
                  onClick={(e) => {
                    if (isLoggedin) {
                      if (e.target.className == "fa-solid fa-star coin_star") {
                        console.log(
                          Favs.filter(
                            (coin) =>
                              coin.attributes.coin_id == ele.id.toString()
                          )
                        );
                        RemoveFavourite(
                          Favs.filter(
                            (coin) =>
                              coin.attributes.coin_id == ele.id.toString()
                          )[0]["id"]
                        );
                        e.target.className = "fa-regular fa-star coin_star";
                        e.target.style.color =
                          theme == "light" ? "black" : "white";
                      } else {
                        console.log("yes");
                        AddFavourite(ele.id.toString());
                        e.target.className = "fa-solid fa-star coin_star";
                        e.target.style.color = "rgb(55, 114, 255)";
                      }
                    }
                  }}
                ></span>
              </td>
              <td>{i + 1 + itemstart}</td>
              {Columns.map((col, i) => {
                if (col.attributes.name == "Name") {
                  return (
                    <td>
                      <div style={{ display: "flex", gap: "10px" }} to="#">
                        <img
                          style={{
                            width: "30px",
                            height: "30px",
                            borderRadius: "50%",
                          }}
                          src={
                            ele.attributes?.icon_url?.length > 0
                              ? ele.attributes?.icon_url
                              : process.env.REACT_APP_SERVER_URL +
                                ele.attributes?.icon?.data?.attributes?.url
                          }
                          alt="rockie"
                        />
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                          }}
                        >
                          <span
                            style={{
                              fontWeight: "bold",
                              fontSize: "16px",
                              whiteSpace: "nowrap",
                            }}
                          >
                            {ele.attributes.name}
                          </span>
                          <span
                            style={{
                              fontSize: "12px",
                              color: "gray",
                              fontWeight: "500",
                            }}
                          >
                            {ele.attributes.ticker}
                          </span>
                        </div>
                      </div>
                    </td>
                  );
                } else if (col.attributes.name == "BlockChain") {
                  return (
                    <td>
                      <div
                        style={{
                          display: "flex",
                          flexWrap: "wrap",
                          gap: "3px",
                        }}
                      >
                        {ele.attributes.blockchains?.data?.map((chain) => {
                          return (
                            <>
                              <img
                                style={{
                                  width: "20px",
                                  height: "20px",
                                  borderRadius: "50%",
                                }}
                                src={
                                  process.env.REACT_APP_SERVER_URL +
                                  BlockChains[chain.attributes.title]
                                }
                                alt="rockie"
                              />
                            </>
                          );
                        })}
                      </div>
                    </td>
                  );
                } else if (col.attributes.name == "Platform") {
                  return (
                    <td>
                      <div
                        style={{
                          display: "flex",
                          flexWrap: "wrap",
                          gap: "3px",
                        }}
                      >
                        {ele.attributes.platforms?.data.map((chain) => {
                          return (
                            <>
                              <img
                                style={{
                                  width: "20px",
                                  height: "20px",
                                }}
                                src={
                                  process.env.REACT_APP_SERVER_URL +
                                  Platforms[chain.attributes.title]
                                }
                                alt="blockchains"
                              />
                            </>
                          );
                        })}
                      </div>
                    </td>
                  );
                } else if (col.attributes.name == "Genre") {
                  return (
                    <td style={{ fontSize: "13px" }}>
                      {ele.attributes?.genres?.data?.map((gen, i) => {
                        if (ele.attributes?.genres?.data.length > 1) {
                          if (i != ele.attributes?.genres?.data.length - 1) {
                            return <div>{gen.attributes.title},</div>;
                          } else {
                            return <div>{gen.attributes.title}</div>;
                          }
                        } else {
                          return <div>{gen.attributes.title}</div>;
                        }
                      })}
                    </td>
                  );
                } else if (col.attributes.name == "Status") {
                  return (
                    <td style={{ fontSize: "13px" }}>
                      {ele.attributes?.statuses?.data?.map((gen, i) => {
                        if (ele.attributes?.statuses?.data.length > 1) {
                          if (i != ele.attributes?.statuses?.data.length - 1) {
                            return <div>{gen.attributes.title},</div>;
                          } else {
                            return <div>{gen.attributes.title}</div>;
                          }
                        } else {
                          return <div>{gen.attributes.title}</div>;
                        }
                      })}
                    </td>
                  );
                } else {
                  if (
                    !isNaN(
                      ele.attributes[ColumnsConversions[col.attributes.name]]
                    )
                  ) {
                    if (col.attributes.name.includes("%")) {
                      if (
                        parseFloat(
                          ele.attributes[
                            ColumnsConversions[col.attributes.name]
                          ]
                        ).toFixed(2) != "NaN"
                      ) {
                        if (
                          ele.attributes[
                            ColumnsConversions[col.attributes.name]
                          ]?.toString()[0] == "-"
                        ) {
                          return (
                            <td
                              key={i}
                              style={{
                                fontWeight: "300",
                                color: "#d33535",
                              }}
                            >
                              {parseFloat(
                                ele.attributes[
                                  ColumnsConversions[col.attributes.name]
                                ]
                              ).toFixed(2) == "NaN"
                                ? "-"
                                : parseFloat(
                                    ele.attributes[
                                      ColumnsConversions[col.attributes.name]
                                    ]
                                  ).toFixed(2)}
                              %
                            </td>
                          );
                        } else {
                          return (
                            <td
                              key={i}
                              style={{
                                fontWeight: "300",
                                color: "#58bd7d",
                              }}
                            >
                              +
                              {parseFloat(
                                ele.attributes[
                                  ColumnsConversions[col.attributes.name]
                                ]
                              ).toFixed(2) == "NaN"
                                ? "-"
                                : parseFloat(
                                    ele.attributes[
                                      ColumnsConversions[col.attributes.name]
                                    ]
                                  ).toFixed(2)}
                              %
                            </td>
                          );
                        }
                      } else {
                        return (
                          <td
                            key={i}
                            style={{
                              fontWeight: "300",
                              color: "#58bd7d",
                            }}
                          >
                            -
                          </td>
                        );
                      }
                    } else if (
                      col.attributes.name == "Trust Rating" ||
                      col.attributes.name == "Earnings Rating"
                    ) {
                      let num = parseFloat(
                        ele.attributes[ColumnsConversions[col.attributes.name]]
                      );
                      if (num >= 7) {
                        return (
                          <td key={i}>
                            <div className="rating green">{num}</div>
                          </td>
                        );
                      } else if (num >= 3) {
                        return (
                          <td key={i}>
                            <div className="rating yellow">{num}</div>
                          </td>
                        );
                      } else if (num >= 0) {
                        return (
                          <td key={i}>
                            <div className="rating red">{num}</div>
                          </td>
                        );
                      }
                    } else {
                      if (
                        ele.attributes[
                          ColumnsConversions[col.attributes.name]
                        ] == null ||
                        ele.attributes[
                          ColumnsConversions[col.attributes.name]
                        ] == 0
                      ) {
                        return <td>-</td>;
                      } else {
                        if (
                          ele.attributes[
                            ColumnsConversions[col.attributes.name]
                          ] == true ||
                          ele.attributes[
                            ColumnsConversions[col.attributes.name]
                          ] == false
                        ) {
                          if (
                            ele.attributes[
                              ColumnsConversions[col.attributes.name]
                            ] == true
                          ) {
                            return (
                              <td>
                                <i
                                  style={{ color: "#58bd7d" }}
                                  className="fa-solid fa-check"
                                ></i>
                              </td>
                            );
                          } else {
                            return (
                              <td>
                                <i
                                  style={{ color: "#d33535" }}
                                  className="fa-solid fa-times"
                                ></i>
                              </td>
                            );
                          }
                        } else {
                          if (
                            parseFloat(
                              ele.attributes[
                                ColumnsConversions[col.attributes.name]
                              ]
                            ) >= 1000000
                          ) {
                            return (
                              <td key={i}>
                                <div>
                                  {new Intl.NumberFormat("en-US", {
                                    style: "currency",
                                    currency: "USD",
                                  })
                                    .format(
                                      parseFloat(
                                        ele.attributes[
                                          ColumnsConversions[
                                            col.attributes.name
                                          ]
                                        ]
                                      )
                                    )
                                    .toString()
                                    .split(".")[0] == "NaN"
                                    ? "-"
                                    : new Intl.NumberFormat("en-US", {
                                        style: "currency",
                                        currency: "USD",
                                      })
                                        .format(
                                          parseFloat(
                                            ele.attributes[
                                              ColumnsConversions[
                                                col.attributes.name
                                              ]
                                            ]
                                          )
                                        )
                                        .toString()
                                        .split(".")[0]}
                                </div>
                              </td>
                            );
                          } else {
                            if (
                              parseFloat(
                                ele.attributes[
                                  ColumnsConversions[col.attributes.name]
                                ]
                              ) > 0.01
                            ) {
                              return (
                                <td key={i}>
                                  {new Intl.NumberFormat("en-US", {
                                    style: "currency",
                                    currency: "USD",
                                  }).format(
                                    parseFloat(
                                      ele.attributes[
                                        ColumnsConversions[col.attributes.name]
                                      ]
                                    )
                                  )}
                                </td>
                              );
                            } else {
                              if (
                                ele.attributes[
                                  ColumnsConversions[col.attributes.name]
                                ]
                              ) {
                                return (
                                  <td key={i}>
                                    $
                                    {parseFloat(
                                      ele.attributes[
                                        ColumnsConversions[col.attributes.name]
                                      ]
                                    ).toFixed(4)}
                                  </td>
                                );
                              } else {
                                return <div></div>;
                              }
                            }
                          }
                        }
                      }
                    }
                  } else {
                    return (
                      <td key={i}>
                        {
                          ele.attributes[
                            ColumnsConversions[col.attributes.name]
                          ]
                        }
                      </td>
                    );
                  }
                }
              })}

              {is_trade == true ? (
                <td>
                  <a
                    target="_blank"
                    href={ele.attributes.trade_link}
                    className="btn"
                  >
                    Trade
                  </a>
                </td>
              ) : (
                <td>
                  <a
                    target="_blank"
                    href={ele.attributes.play_link}
                    className="btn"
                  >
                    Play
                  </a>
                </td>
              )}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}

export default CoinsTable;
