import React, { useEffect, useState } from "react";
import CoinDetails from "./CoinDetails";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import axios from "axios";
import { useParams } from "react-router-dom";

function CoinDetailsMain() {
  const [Tables, setTables] = useState([]);
  const [SelectedTable, setSelectedTable] = useState("investing");
  const { id } = useParams();

  useEffect(() => {
    axios(process.env.REACT_APP_PROXY_URL + `/getCoinSlug/${id}`).then(
      (res) => {
        if (res.status == 200) {
          console.log("resdata");
          setSelectedTable(
            res.data.data[0].attributes.tables.data[0].attributes.slug
          );
          setTables(res.data.data[0].attributes.tables.data);
        }
      }
    );
  }, [id]);

  return (
    <div className="coin-list" style={{ paddingTop: "10px" }}>
      <div className="coin-list__main">
        <Tabs>
          <TabList
            className="container react-tabs__tab-list"
            style={{ paddingLeft: "30px" }}
          >
            {Tables.map((ele) => {
              return (
                <Tab
                  key={ele.id}
                  onClick={() => {
                    setSelectedTable(ele.attributes.slug);
                  }}
                >
                  {ele.attributes.name}
                </Tab>
              );
            })}
          </TabList>
          <TabPanel>
            <CoinDetails table={SelectedTable} id={id} />
          </TabPanel>
          <TabPanel>
            <CoinDetails table={SelectedTable} id={id} />
          </TabPanel>
        </Tabs>
      </div>
    </div>
  );
}

export default CoinDetailsMain;
